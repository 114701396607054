import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {useUser} from "../../contexts/UserContext";
import {request} from "../../services/AxiosService";


export default function MyClubs()
{
    const navigate = useNavigate();

    const {setManagedClub} = useUser();
    const [clubs, setClubs] = useState([]);


    useEffect(() =>
    {
        request("GET", "/panel/clubs").then(response => 
        {
            setClubs(response.data);
        })
        .catch(() =>
        {
            Swal.fire("Ocurrió un error", "Al parecer no tienes permisos para ver esta página.", "error").then(() =>
            {
                navigate("/");
            });
        });
    }, [navigate]);

    useEffect(() =>
    {
        if(clubs.length === 1) 
        {
            onSelectClub(clubs[0]);
        }
    });


    const onSelectClub = club =>
    {
        setManagedClub(club);
        navigate("/panel/reservas");
    };


    return (
        <main className="container-xl py-5">
            <div className="mb-5 text-center text-md-start">
                <h2 className="mb-0">Mis clubes</h2>
                <p className="text-muted">Selecciona el club que quieres administrar</p>
            </div>

            {(clubs) ?
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {clubs.map(club =>
                    {
                        return (
                            <div className="col" key={club.id}>
                                <div className="p-2 bg-primary bg-opacity-10 border border-primary rounded-3 shadow">
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <div className="hstack gap-3">
                                            <div>
                                                <img src={club.logoUrl} alt="Logo" style={{width: "4rem", height: "4rem"}}/>
                                            </div>

                                            <div>
                                                <h3 className="mb-0">{club.name}</h3>
                                            </div>
                                        </div>

                                        <div className="p-3">
                                            <button className="btn btn-sm btn-primary" onClick={() => onSelectClub(club)}>
                                                <div className="hstack gap-2">
                                                    <span className="fw-semibold">Administrar</span>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                :
                <p className="fs-5 text-center text-muted">Aún no tienes clubes asignados</p>
            }
        </main>
    )
}
