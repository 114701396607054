import Home from "../views/public/Home";
import {IndexLayout} from "./layouts";


const indexRoute = 
[
    {
        element: <IndexLayout/>,
        children:
        [
            {path: "/", element: <Home/>}
        ]
    }
];

export default indexRoute;