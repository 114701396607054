import {useEffect, useState} from "react";

import {useUser} from "../../contexts/UserContext";


export default function SubscriptionStatusAlert()
{
    const {managedClub} = useUser();
    const [suscripcion, setSuscripcion] = useState(null);

    const [text, setText] = useState("");
    const [alertClass, setAlertClass] = useState("");


    useEffect(() =>
    {
        if(!managedClub) return;

        setSuscripcion(managedClub.subscription);
    }, [managedClub]);


    useEffect(() =>
    {
        if(!suscripcion) return;

        switch(suscripcion.state)
        {
            case "ACTIVE":
            {
                setText("La suscripción del club está activa.");
                setAlertClass("alert-success");
                break;
            }
            case "ABOUT_TO_EXPIRE":
            {
                if(suscripcion.daysUntilExpiration === 0) setText("La suscripción del club vence hoy.");
                else setText(`La suscricpión del club vencerá en ${suscripcion.daysUntilExpiration} días`);

                setAlertClass("alert-warning");
                break;
            }
            case "EXPIRED":
            {
                setText(`La suscripción del club venció hace ${suscripcion.daysUntilExpiration} días. Renuévala hoy para poder seguir usando el software.`);
                setAlertClass("alert-danger");
                break;
            }
            default:
            {
                setText("La suscripción del club venció y ya no está disponible para tus jugadores. Renuévala hoy para poder seguir usando el software.");
                setAlertClass("alert-dark");
                break;
            }
        }
    }, [suscripcion]);

    if(!suscripcion || suscripcion.state === "ACTIVE") return null;

    return (
        <div className={`alert ${alertClass} alert-dismissible fade show mt-1 mb-2`} role="alert">
            <span className="fw-semibold">IMPORTANTE:</span> {text}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    );
}