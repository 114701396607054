import {useEffect, useState} from "react";
import {request} from "../../services/AxiosService";


export default function Plans()
{
    const [plans, setPlans] = useState([]);
    const [mpPlans, setMpPlans] = useState([]);


    // Obtener todos los planes de pago a través de la api de mercadopago
    useEffect(() =>
    {
        // Planes definidos en el sistema
        request("GET", "/plans/").then(response => {
            setPlans(response.data);
        });

        // Planes definidos en MercadoPago
        request("GET", "/admin/mercadopago/plans").then(response => {
            setMpPlans(response.data);
        });
    }, []);


    return (
        <main className="container py-5">
            <div className="mb-5">
                <div className="mb-3">
                    <h2>Planes</h2>

                    <button className="btn btn-primary" type="button">Nuevo plan</button>
                </div>

                <div className="overflow-x-auto">
                    <table className="mb-0 table table-light table-striped mb-0 border align-middle shadow-none">
                        <thead>
                            <tr>
                                <th className="text-nowrap"># de canchas</th>
                                <th className="text-nowrap">Precio original</th>
                                <th className="text-nowrap">Descuento</th>
                                <th className="text-nowrap">Precio final</th>
                                <th className="text-nowrap"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {plans.map(plan =>
                            {
                                return (
                                    <tr key={plan.id}>
                                        <td>
                                            <div className="hstack gap-2">
                                                <i className="fa-regular fa-court-sport text-primary"></i>
                                                <span>
                                                    {(plan.maxCourts === 0) && "10 canchas o más"}
                                                    {(plan.maxCourts === 1) && "1 cancha"}
                                                    {(plan.maxCourts > 1) && `${plan.maxCourts} canchas`}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="text-nowrap">$ {parseInt(plan.montlyPrice).toLocaleString()}</td>
                                        <td className="text-nowrap">
                                            {(plan.discount) ? (`${plan.discount}%`) : ("No aplica")}
                                        </td>
                                        <td className="text-nowrap fw-bold text-success">$ {parseInt(plan.finalPrice).toLocaleString()}</td>
                                        {/* <td>{plan.subscribed}</td> */}
                                        <td>
                                            {/* <a href={"https://www.mercadopago.com.ar/subscription-plans/edit?id=" + plan.id} className="btn btn-warning" target="_blank" rel="external noopener noreferrer">
                                                <i className="fa-regular fa-pen-square"></i>
                                            </a> */}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mb-5">
                <div className="mb-3">
                    <h2>Planes de pago con MercadoPago</h2>

                    <a href="https://www.mercadopago.com.ar/subscription-plans/list" className="btn btn-primary" target="_blank" rel="external noopener noreferrer">
                        Nuevo plan
                    </a>
                </div>

                <div className="overflow-x-auto">
                    <table className="mb-0 table table-light table-striped mb-0 border align-middle shadow-none">
                        <thead>
                            <tr>
                                <th className="text-nowrap">Nombre</th>
                                <th className="text-nowrap">Precio</th>
                                <th className="text-nowrap">Frec. de cobro</th>
                                <th className="text-nowrap">Día de cobro</th>
                                <th className="text-nowrap">Free trial</th>
                                <th className="text-nowrap">Usuarios suscritos</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {mpPlans.results?.map(plan =>
                            {
                                return (
                                    <tr key={plan.id}>
                                        <td className="fw-semibold text-nowrap">{plan.reason}</td>
                                        <td className="fw-bold text-nowrap text-success">$ {plan.auto_recurring.transaction_amount.toLocaleString()} {plan.auto_recurring.currency_id}</td>
                                        <td>{plan.auto_recurring.frequency} {plan.auto_recurring.frequency_type}</td>
                                        <td>{(plan.auto_recurring.billing_day) ? (plan.auto_recurring.billing_day) : "No definido"}</td>
                                        <td>
                                            {(plan.auto_recurring.free_trial === undefined) && <span>Sin prueba gratis</span>}
                                            {(plan.auto_recurring.free_trial !== undefined) && <span>{plan.auto_recurring.free_trial.frequency} {plan.auto_recurring.free_trial.frequency_type}</span>}
                                        </td>
                                        <td>{plan.subscribed}</td>
                                        <td>
                                            <a href={"https://www.mercadopago.com.ar/subscription-plans/edit?id=" + plan.id} className="btn btn-warning" target="_blank" rel="external noopener noreferrer">
                                                <i className="fa-regular fa-pen-square"></i>
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
} 