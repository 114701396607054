import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "./UserContext"


const ProtectedRoute = ({role}) =>
{
    const {user, JWT} = useUser();

    if(!JWT) return <Navigate to={"/login"}/>
    if(user && !user.roles.includes(role)) return <Navigate to={"/404"}/>

    return <Outlet/>
}

export default ProtectedRoute;