import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";


export default function Header({home})
{
    return (
        <header className={`${(home === true) ? "mb-5" : "text-bg-dark"}`}>
            <div className="container-fluid px-md-5 py-4">
                <nav className="d-flex fa-wrap align-items-center justify-content-between gap-3">
                    <div>
                        <h1 className="mb-0 fw-normal text-uppercase">
                            <Link to={"/"} className="text-decoration-none text-reset">
                                <span>Reservar</span>
                                <span className="fw-bolder text-primary">Cancha</span>
                            </Link>
                        </h1>
                    </div>

                    <Navbar></Navbar>
                </nav>
            </div>
        </header>
    );
}
