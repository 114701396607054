import React, {useEffect, useState} from "react";
import { request } from "../../services/AxiosService";
import Swal from "sweetalert2";
import moment from "moment";


export default function NewFixedReservationForm({fixedReservation, court, modal, onSubmit})
{
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        if(!court) return;

        setForm(
        {
            id: (fixedReservation) ? (fixedReservation.id) : null,
            courtId: court.id,
            sportId: (fixedReservation) ? (fixedReservation.sport.id) : (null),
            startDate: (fixedReservation) ? (fixedReservation.startDate) : (null),
            startHour: (fixedReservation) ? (fixedReservation.startHour) : (null),
            endHour: (fixedReservation) ? (fixedReservation.endHour) : (null),
            weeksDuration: (fixedReservation) ? (fixedReservation.weeksDuration) : (null),
        });
    }, [fixedReservation, court]);


    // Form
    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/panel/fixed-reservations/new", form).then(() => 
        {
            onSubmit(); // Actualizar el componente padre
            setForm({});
            setErrors({});

            Swal.fire(
            {
                position: "top-end",
                icon: "success",
                title: "Reserva fija creada",
                showConfirmButton: false,
                backdrop: false,
                timer: 2000
            });
        })
        .catch(error =>
        {   
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    return (
        <div className="modal" id={modal} tabIndex="-1" aria-labelledby="reservaLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title">{(fixedReservation) ? ("Datos de la reserva fija") : ("Nueva reserva fija")}</h5>

                            <div className="hstack gap-2 text-muted">
                                <i className="fa-regular fa-court-sport"></i>
                                <span>{court.name}</span>
                            </div>
                        </div>

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sportId">Deporte <span className="text-danger">*</span></label>
                                <select className="form-select" id="sportId" value={form.sportId || ""} onChange={handleChange} required>
                                    <option value="" disabled>-- Seleccionar opción --</option>
                                    {court.courtSports.map(courtSport =>
                                    {
                                        return (
                                            <option value={courtSport.sport.id} key={courtSport.id}>{courtSport.sport.name}</option>
                                        )
                                    })}
                                </select>
                                <small className="text-danger">{errors.sportId}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="startDate">Fecha de inicio <span className="text-danger">*</span></label>
                                <input className="form-control" type="date" id="startDate" value={form.startDate || ""} onChange={handleChange} min={moment().format("yyyy-mm-DD")} required/>
                                <small className="text-danger">{errors.startDate}</small>
                                <div className="form-text">Indica que día empezará la reserva fija. Las reservas se repetirán cada semana en el mismo día.</div>
                            </div>

                            <div className="mb-3 hstack gap-3">
                                <div className="w-100">
                                    <label className="form-label" htmlFor="startHour">Hora de inicio <span className="text-danger">*</span></label>
                                    <input className="form-control" type="number" id="startHour" value={form.startHour || ""} onChange={handleChange} min={0} max={23} required/>
                                    <small className="text-danger">{errors.startHour}</small>
                                </div>

                                <div className="w-100">
                                    <label className="form-label" htmlFor="endHour">Hora de finalización <span className="text-danger">*</span></label>
                                    <input className="form-control" type="number" id="endHour" value={form.endHour || ""} onChange={handleChange} min={1} max={24} required/>
                                    <small className="text-danger">{errors.endHour}</small>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="weeksDuration">Semanas</label>
                                <input className="form-control" type="number" id="weeksDuration" value={form.weeksDuration || ""} onChange={handleChange} min={4} max={52} required/>
                                <small className="text-danger">{errors.weeksDuration}</small>
                                {/* <div className="form-text">Cantidad de semanas que durará la reserva fija, comenzando desde la fecha de inicio.</div> */}
                            </div>

                            <hr/>

                            <div className="mb-3">
                                <small className="fw-semibold">Datos del jugador</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="playerName">Nombre</label>
                                <input className="form-control" type="text" id="playerName" value={form.playerName || ""} onChange={handleChange} placeholder="Juan Perez" required/>
                                <small className="text-danger">{errors.playerName}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="playerPhone">Teléfono</label>
                                <input className="form-control" type="text" id="playerPhone" value={form.playerPhone || ""} onChange={handleChange}/>
                                <small className="text-danger">{errors.playerPhone}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="notes">Notas</label>
                                <textarea className="form-control" id="notes" value={form.notes || ""} onChange={handleChange} rows={2}></textarea>
                                <small className="text-danger">{errors.notes}</small>
                            </div>

                            <hr />

                            <div className="d-flex justify-content-end gap-2">
                                <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancelar</button>
                                <button className="btn btn-success" type="submit">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
