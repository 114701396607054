import {Outlet} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import React from "react";
import Sidebar from "../components/panel/Sidebar";
import BottomNavbar from "../components/panel/BottomNavbar";
import PanelHeader from "../components/panel/PanelHeader";
import SubscriptionStatusAlert from "../components/panel/SubscriptionStatusAlert";


export const IndexLayout = () => (
    <>
        <Header home={true}/>
        <Outlet/>
        <Footer/>
    </>
);

export const PublicLayout = () => (
    <>
        <Header/>
        <Outlet/>
        <Footer/>
    </>
);

export const PanelLayout = () => (
    <div className="bg-light">
        <PanelHeader/>

        <div className="container-xl py-2 py-lg-4">
            <div className="d-flex flex-column flex-lg-row">
                <div className="d-none d-lg-block">
                    <Sidebar></Sidebar>
                </div>

                <div className="flex-fill" style={{minWidth: 0}}>
                    <SubscriptionStatusAlert/>

                    <Outlet/>
                    
                    <div className="py-5"></div>
                </div>
            </div>
        </div>

        <div className="d-block d-lg-none">
            <BottomNavbar> </BottomNavbar>
        </div>
    </div>
);

export const panelLinks =
[
    {title: "Reservas",         href: "reservas",           icon: "fa-clipboard-check"},
    {title: "Reservas fijas",   href: "reservas-fijas",     icon: "fa-thumbtack"},
    {title: "Canchas",          href: "canchas",            icon: "fa-court-sport"},
    {title: "Horarios",         href: "horarios",           icon: "fa-calendars"},
    {title: "Staff",            href: "staff",              icon: "fa-users"},
    {title: "Suscripción",      href: "suscripcion",        icon: "fa-money-check"},
    {title: "Configuración",    href: "configuracion",      icon: "fa-gear"}
];