import {useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";


export default function ClubForm({club, onSubmit})
{
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        if(!club) return;
        
        setForm(club);
    }, [club]);


    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onChangeIframe = e =>
    {
        const text = e.target.value;
        const regex = /src\s*="([^"]*)"/;
        const match = text.match(regex);
        const src = (match) ? (match[1]) : (null);

        setForm({...form, iframeSrc: src});
    }

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/panel/configuration/form?clubId=${club.id}`, form).then(() => 
        {
            onSubmit();
            Swal.fire("Datos guardados correctamente", "", "success");
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <form onSubmit={onFormSubmit} autoComplete="off">
            <div className="mb-3">
                <label className="form-label" htmlFor="name">Nombre</label>
                <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} placeholder="Nombre del club" required/>
                <small className="text-danger">{errors.name}</small>
            </div>

            <div className="mb-3">
                <label className="form-label" htmlFor="slug">Slug {"(URL)"}</label>
                <div className="input-group">
                    <span className="input-group-text">/clubes/</span>
                    <input className="form-control" type="text" id="slug" value={form.slug || ""} onChange={handleChange} placeholder="nombre-del-club" required/>
                </div>
                <small className="text-danger">{errors.slug}</small>
                <div className="form-text">No modifiques la URL del club a menos que sea necesario.</div>
            </div>

            <div className="mb-3">
                <label className="form-label" htmlFor="address">Dirección</label>
                <input className="form-control" type="text" id="address" value={form.address || ""} onChange={handleChange} placeholder="Calle Ejemplo, 200" required/>
                <small className="text-danger">{errors.address}</small>
            </div>

            <div className="mb-3">
                <label className="form-label" htmlFor="iframeSrc"><code>{"<iframe>"}</code> de Google Maps</label>
                <input className="form-control" id="iframeSrc" value={(form.iframeSrc) ? (`<iframe src="${form.iframeSrc}"></iframe>`) : ""} onChange={onChangeIframe} placeholder="Pegar <iframe> aquí"/>
                <small className="text-danger">{errors.iframeSrc}</small>
                <div className="form-text">En Google Maps (desde una PC/portatil) busca tu complejo deportivo, luego <code>Compartir {">"} Insertar un mapa {">"} COPIAR HTML</code>.</div>
            </div>

            <div className="mb-3">
                <label className="form-label" htmlFor="logoUrl">Logo</label>
                <input className="form-control" type="url" id="logoUrl" value={form.logoUrl || ""} onChange={handleChange} placeholder="https://" required/>
                <small className="text-danger">{errors.logoUrl}</small>
            </div>

            <div className="mb-3">
                <label className="form-label" htmlFor="coverUrl">Portada</label>
                <input className="form-control" type="url" id="coverUrl" value={form.coverUrl || ""} onChange={handleChange} placeholder="https://"/>
                <small className="text-danger">{errors.coverUrl}</small>
            </div>

            <div className="mt-4">
                <button className="btn btn-primary fw-bold" type="submit">Guardar cambios</button>
            </div>
        </form>
    );
}