import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment";

import {request} from "../../services/AxiosService";


export default function Users()
{
    const [users, setUsers] = useState([]);


    // Obtener todos los usuarios, ordenados por rol
    useEffect(() =>
    {
        request("GET", "/admin/users/").then(response => {
            setUsers(response.data);
        });
    }, []);

    


    return (
        <main className="container my-5">
            <div className="mb-4">
                <h2>Lista de usuarios</h2>
            </div>

            <div className="border rounded">
                <div className="p-3 border-bottom">
                    <p className="mb-0 text-muted">{users.length} usuarios registrados</p>
                </div>

                <div className="overflow-x-auto">
                    <table className="mb-0 table table-hover align-middle caption-top">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo electrónico</th>
                                <th>Rol</th>
                                <th>Fecha de registro</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {users.map(user =>
                            {
                                
                                return (
                                    <tr key={user.id}>
                                        <td className="fw-semibold">
                                            <div className="hstack gap-2">
                                                <i className="fa-regular fa-user text-primary"></i>
                                                <span>{user.name} {user.surname}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href={"mailto:" + user.email}>{user.email}</a>
                                        </td>
                                        <td>{user.role}</td>
                                        <td>{moment(user.createdAt).format("lll")}</td>
                                        <td className="text-end">
                                            <Link to={`/admin/usuarios/form?id=${user.id}`} className="btn btn-sm btn-light">
                                                <i className="fa-regular fa-pen-square fa-lg"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
} 