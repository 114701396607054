import {Link, useLocation} from "react-router-dom";

import {panelLinks} from "../../routes/layouts";


export default function BottomNavbar()
{
    const location = useLocation();
    

    return (
        <div className="fixed-bottom">
            <div className="text-bg-dark">
                <div className="d-flex justify-content-around overflow-x-auto">
                    {panelLinks.map(link =>
                    {
                        const pathname = "/panel/" + link.href + "/";
                        const active = ((location.pathname + "/").startsWith(pathname));

                        const btnClass = (active) ? ("btn-primary") : ("btn-dark");
                        const iconClass = (active) ? ("fa-solid") : ("fa-thin");

                        return (
                            <Link to={link.href} className={`btn ${btnClass} p-3 rounded-0`} key={link.href}>
                                <div className="d-flex flex-column align-items-center justify-content-center gap-2" style={{minWidth: "6rem"}}>
                                    <i className={`${iconClass} ${link.icon} fa-fw`}></i>
                                    <small className="fw-bold">{link.title}</small>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
