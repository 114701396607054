import {parse, format} from "date-fns";


export const formatTime = (time, pattern) => 
{
    const parsedTime = parse(time, "HH:mm:ss", new Date());
    return format(parsedTime, pattern);
};

export const formatDate = (date, pattern) => 
{
    const parsedTime = parse(date, "yyyy-MM-dd", new Date());
    return format(parsedTime, pattern);
};