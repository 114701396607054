import React, {useCallback, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import {request} from "../../services/AxiosService";
import SubscriptionPaymentForm from "../../components/admin/SubscriptionPaymentForm";


export default function Subscription()
{
    const [params] = useSearchParams();

    const [club, setClub] = useState(null);

    // Obtener datos del club
    const fetchClub = useCallback(() =>
    {
        const clubId = params.get("clubId");

        request("GET", `/admin/clubs/${clubId}?payments=1`).then(response => {
            setClub(response.data);
        }).catch(error => {
            Swal.fire("Ocurrió un error", `${JSON.stringify(error.response.data || error)}`, "error");
        });
    }, [params]);
    
    useEffect(() =>
    {
        fetchClub();
    }, [fetchClub]);


    return (
        <main className="container my-4">
            <div className="mb-3">
                <h2>Suscripción</h2>

                <Link to={"/admin/clubes/"} className="btn btn-primary">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-chevron-left"></i>
                        <span>Volver</span>
                    </div>
                </Link>
            </div>
            
            {(club) &&
                <div className="row row-cols-1 row-cols-lg-2 g-4">
                    <div className="col">
                        <div className="p-4 bg-white border rounded-3">
                            <h3 className="mb-3">Detalles del club</h3>

                            <div className="vstack gap-2">
                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="mb-0 fw-semibold">Nombre</span>
                                    <span className="fs-5 fw-bold">{club.name}</span>
                                </div>

                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="fw-semibold">Página</span>
                                    <Link to={`/clubes/${club.slug}`}>/{club.slug}</Link>
                                </div>
                                
                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="fw-semibold">Propietario</span>
                                    <Link to={`/admin/usuarios/${club.owner.id}`}>{club.owner.name} {club.owner.surname}</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="p-4 bg-white border rounded-3">
                            <h3 className="mb-3">Suscripción</h3>

                            <div className="vstack gap-2">
                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="mb-0 fw-semibold">Plan</span>
                                    <span className="fw-semibold">
                                        {(club.subscription.plan.maxCourts === 0) && "Más de 10 canchas"}
                                        {(club.subscription.plan.maxCourts === 1) && "1 cancha"}
                                        {(club.subscription.plan.maxCourts > 1) && `${club.subscription.plan.maxCourts} canchas`}
                                    </span>
                                </div>
                                
                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="mb-0 fw-semibold">Estado</span>
                                    <span className="fw-semibold">
                                        {(club.subscription.state === "ACTIVE") && <span className="text-success">Activo</span>}
                                        {(club.subscription.state === "ABOUT_TO_EXPIRE") && <span className="text-warning">Próximo a vencer</span>}
                                        {(club.subscription.state === "EXPIRED") && <span className="text-danger">Vencido</span>}
                                        {(club.subscription.state === "CANCELLED") && <span className="text-black">Cancelado</span>}
                                    </span>
                                </div>

                                <hr/>

                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="fw-semibold">En periodo de prueba</span>
                                    {(club.subscription.freeTrial) ?
                                        <span className="fw-semibold">Sí</span>
                                        :
                                        <span>No</span>
                                    }
                                </div>

                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="fw-semibold">Fecha de inicio</span>
                                    <span>{moment(club.subscription.startDate).format("ll")}</span>
                                </div>

                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="fw-semibold">Próximo pago</span>
                                    <span>{moment(club.subscription.nextPaymentDate).format("ll")}</span>
                                </div>

                                {(club.subscription.daysUntilExpiration >= 0) ?
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <span className="fw-semibold">Vence en</span>
                                        <span>{club.subscription.daysUntilExpiration} días</span>
                                    </div>
                                    :
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <span className="fw-semibold">Venció hace</span>
                                        <span>{-club.subscription.daysUntilExpiration} días</span>
                                    </div>
                                }

                                <div className="d-flex align-items-start justify-content-between gap-2">
                                    <span className="fs-5 fw-semibold">Total a pagar</span>
                                    <span className="fs-5 fw-semibold text-success">$ {club.subscription.plan.finalPrice.toLocaleString()}</span>
                                </div>
                            </div>

                            <hr/>

                            <div className="overflow-x-auto">
                                <p className="fw-semibold text-muted">Historial de pagos</p>

                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Monto</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {club.subscription.payments.map(payment =>
                                        {
                                            const modal = `paymentModal_${payment.id}`;

                                            return (
                                                <tr key={payment.id}>
                                                    <td className="text-muted">{moment(payment.date).format("lll")}</td>
                                                    <td className="fw-semibold text-success text-nowrap">$ {payment.amount.toLocaleString()}</td>
                                                    <td>
                                                        <button className="btn btn-light btn-sm" type="button" data-bs-toggle="modal" data-bs-target={`#${modal}`}>
                                                            <i className="fa-thin fa-edit"></i>
                                                        </button>

                                                        <SubscriptionPaymentForm subscription={club.subscription} payment={payment} modal={modal} onSubmit={fetchClub}> 

                                                        </SubscriptionPaymentForm>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                <div className="mt-3">
                                    <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#paymentModal_new">
                                        <div className="hstack gap-2">
                                            <i className="fa-thin fa-plus"></i>
                                            <span>Registrar pago</span>
                                        </div>
                                    </button>

                                    <SubscriptionPaymentForm subscription={club.subscription} payment={null} modal={"paymentModal_new"} onSubmit={fetchClub}> 

                                    </SubscriptionPaymentForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </main>
    );
} 