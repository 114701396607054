import React, {useEffect, useState} from "react";

import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";
import ReservationCard from "../../components/profile/ReservationCard";
import ReservationModal from "../../components/profile/ReservationModal";


export default function Reservations()
{
    const [selectedTab, setSelectedTab] = useState(null);
    const [activeReservations, setActiveReservations] = useState(null);
    const [pastReservations, setPastReservations] = useState(null);


    useEffect(() =>
    {
        setSelectedTab(0);

        document.title = "Mis reservas | Reservar Cancha"
    }, []);

    useEffect(() =>
    {
        switch(selectedTab)
        {
            case 0: // Proximos partidos
            {
                if(activeReservations !== null) return;
                
                request("GET", "/profile/reservations").then(response => {
                    setActiveReservations(response.data);
                });
                break;
            }
            case 1: // Reservas anteriores
            {
                if(pastReservations !== null) return;
                
                request("GET", "/profile/past-reservations").then(response => 
                {
                    const resversed = response.data.reverse(); // Revertir el array para que vaya de la reserva mas proxima a la mas antigua
                    setPastReservations(resversed);
                });
                break;
            }
            default: return;
        }
    }, [selectedTab, activeReservations, pastReservations]);


    const tabs =
    [
        {title: "Próximos partidos"}, 
        {title: "Reservas anteriores"}
    ];

    const reloadActiveReservations = () =>
    {
        setActiveReservations(null);
        hideModals();
    };

    
    return (
        <main>
            <div className="mb-4 text-center text-lg-start">
                <h3 className="mb-0 fs-2">Mis reservas</h3>
            </div>

            <div className="mb-3 overflow-x-auto">
                <ul className="nav nav-underline nav-fill">
                    {tabs.map((tab, tabIndex) =>
                    {
                        const active = (selectedTab === tabIndex);

                        return (
                            <li className="nav-item fw-semibold" key={tabIndex}>
                                <button className={`nav-link ${(active) ? ("active text-primary") : ("text-reset")}`} aria-current={(active) ? ("page") : ("")} onClick={() => setSelectedTab(tabIndex)} key={tabIndex}>
                                    {tab.title}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>

            {/* Proximos partidos */}
            {(selectedTab === 0) &&
                <div>
                    <div className="mb-3 text-center text-lg-start text-muted">
                        <p className="mb-0">Tus partidos programados para los próximos 7 días</p>
                    </div>

                    {(activeReservations) ?
                        <div className="vstack gap-3">
                            {activeReservations.map(reservation =>
                            {
                                const modal = `reservationModal_${reservation.id}`;
                                
                                return (
                                    <React.Fragment key={reservation.id}>
                                        <ReservationCard reservation={reservation} modal={modal}/>
                                        <ReservationModal reservation={reservation} modal={modal} onDelete={() => reloadActiveReservations()}/>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        :
                        <div className="p-3 border rounded-3">
                            <div className="placeholder-wave">
                                <div className="d-flex align-items-center justify-content-between gap-3">
                                    <div className="hstack gap-3 w-100">
                                        <div className="placeholder rounded-3" style={{width: "5rem", height: "5rem"}}></div>

                                        <div className="vstack justify-content-center gap-2">
                                            <div className="placeholder w-75"></div>
                                            <div className="placeholder w-25"></div>
                                            <div className="placeholder w-50"></div>
                                        </div>
                                    </div>

                                    <div className="placeholder rounded-circle" style={{width: "2rem", height: "2rem"}}></div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

            {/* Reservas pasadas */}
            {(selectedTab === 1) &&
                <div>
                    <div className="mb-3 text-center text-lg-start text-muted">
                        <p className="mb-0">Partidos que jugaste en los últimos 2 meses</p>
                    </div>

                    {(pastReservations) ?
                        <div className="vstack gap-3">
                            {pastReservations.map(reservation =>
                            {
                                const modal = `reservationModal_${reservation.id}`;

                                return (
                                    <React.Fragment key={reservation.id}>
                                        <ReservationCard reservation={reservation} modal={modal}/>
                                        <ReservationModal reservation={reservation} modal={modal}/>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        :
                        <div className="p-3 border rounded-3">
                            <div className="placeholder-wave">
                                <div className="d-flex align-items-center justify-content-between gap-3">
                                    <div className="hstack gap-3 w-100">
                                        <div className="placeholder rounded-3" style={{width: "5rem", height: "5rem"}}></div>

                                        <div className="vstack justify-content-center gap-2">
                                            <div className="placeholder w-75"></div>
                                            <div className="placeholder w-25"></div>
                                            <div className="placeholder w-50"></div>
                                        </div>
                                    </div>

                                    <div className="placeholder rounded-circle" style={{width: "2rem", height: "2rem"}}></div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </main>
    );
} 