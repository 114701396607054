import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {LocalDate, hideModals} from "../../services/UtilsService";
import ReservationForm from "../../components/panel/ReservationForm";
import {useUser} from "../../contexts/UserContext";


export default function Reservations()
{
    const {managedClub} = useUser();

    const [table, setTable] = useState(null);
    const [filters, setFilters] = useState({date: LocalDate.now().toString()});


    // Funcion para obtener todas las reservas segun el club y la fecha
    const fetchClub = useCallback(() =>
    {
        if(!filters || !managedClub) return;

        // Obtener datos del club como las reservas hechas en la fecha seleccionada
        request("GET", `/panel/reservations?clubId=${managedClub.id}&date=${filters.date}`).then(response =>
        {
            setTable(response.data);
            hideModals();
        }).catch(() => { 
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [filters, managedClub]);


    useEffect(() =>
    {
        fetchClub();
    }, [fetchClub]);


    useEffect(() =>
    {
        if(!managedClub) return;
        
        document.title = `Reservas - ${managedClub.name}`;
    }, [managedClub]);


    if(!managedClub) return;
    return (
        <main>
            <div className="mb-3">
                <p className="mb-0 fs-4 fw-semibold">Reservas</p>
                <small className="text-muted">Tabla con las reservas realizadas en el día. Es responsabilidad de cada club gestionar las reservas para poder ofrecer un servicio de reservas en tiempo real. Puedes filtrar las reservas por fecha.</small>
            </div>

            {/*  */}
            <div className="mb-4">
                <h3 className="fw-bold fs-5">Resumen</h3>

                <div className="p-4 bg-white border rounded shadow-sm">
                    <div className="row g-3">
                        <div className="col-12 col-sm-6 col-xl">
                            <label className="form-label text-muted" htmlFor="date">Fecha</label>
                            <input className="form-control" type="date" id="date" value={filters.date || ""} onChange={e => setFilters({date: e.target.value})}/>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h3 className="fw-bold fs-5">
                    {(filters.date < moment().subtract(1, "days").format("yyyy-MM-DD")) && <>Reservas del {moment().subtract(1, "days").format("dddd[, ]LL")}</>}
                    {(filters.date === moment().subtract(1, "days").format("yyyy-MM-DD")) && "Reservas de ayer"}
                    {(filters.date === moment().format("yyyy-MM-DD")) && "Reservas de hoy"}
                    {(filters.date === moment().add(1, "days").format("yyyy-MM-DD")) && "Reservas de mañana"}
                    {(filters.date > moment().add(1, "days").format("yyyy-MM-DD")) && <>Reservas del {moment().subtract(1, "days").format("dddd[, ]LL")}</>}
                </h3>

                {(table) &&
                    <div className="overflow-x-auto shadow">
                        <table className="table table-bordered mb-0">
                            <thead className="align-middle text-center">
                                <tr>
                                    <th scope="col">
                                        <i className="fa-regular fa-stopwatch text-muted"></i>
                                    </th>
                                    {table.courts.map(court =>
                                    {
                                        return (
                                            <th scope="col" key={court.id}>
                                                {court.name}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>

                            <tbody>
                                {table.timeslots.map(timeslot =>
                                {
                                    const timeslotTime = new Date(timeslot);
                                    
                                    return (
                                        <tr key={timeslot}>
                                            <td className="px-3 text-muted ls-1">
                                                {moment(timeslot).format("HH:mm")}
                                            </td>

                                            {table.courts.map(court =>
                                            {
                                                const reservation = court.reservations.find(reservation =>
                                                {
                                                    const startTime = new Date(reservation.startTime);
                                                    const endTime = new Date(reservation.endTime);
                                                    return (timeslotTime >= startTime && timeslotTime < endTime);
                                                });

                                                if(reservation)
                                                {
                                                    if(reservation.startTime === timeslot)
                                                    {
                                                        const modal = `reservationModal_${reservation.id}`;

                                                        return (
                                                            <td className="bg-white" rowSpan={Math.max(1, Math.ceil(reservation.duration / 30))} style={{height: "1px"}} key={court.id}>
                                                                <button className="btn btn-success w-100 h-100" type="button" data-bs-toggle="modal" data-bs-target={"#" + modal}>
                                                                    {(reservation.user) ?
                                                                        <React.Fragment>
                                                                            <h5 className="mb-0 fs-6 text-wrap">{reservation.user.name}</h5>
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            <h5 className="mb-0 fs-6 text-wrap">{reservation.playerName}</h5>
                                                                        </React.Fragment>
                                                                    }
                                                                </button>

                                                                <ReservationForm reservation={reservation} court={court} modal={modal} onSubmit={fetchClub}>

                                                                </ReservationForm>
                                                            </td>
                                                        );
                                                    }

                                                    return null;
                                                }
                                                return <td key={court.id}></td>;
                                            })}
                                        </tr>
                                    );
                                })}                                
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </main>
    );
}
