import {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";


export default function UserForm()
{
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState({});

    // Obtener datos del usuario
    useEffect(() =>
    {
        const id = params.get("id"); // Obtener id del usuario desde la url
        if(id == null) 
        {
            Swal.fire("Ocurrió un problema", "Falta el parámetro de 'ID'", "error").then(() =>
            {
                navigate("/admin/usuarios/");
            });
            return;
        }

        // Hacer peticion
        request("GET", `/admin/users/form?id=${id}`).then(response => 
        {
            const {planId} = response.data;
            setForm({...response.data, planId});
        });

        // Obtener lista de roles
        request("GET", "/admin/roles/").then(response => {
            setRoles(response.data);
        });
    }, [params, navigate]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/admin/users/form", form).then(() => 
        {
            Swal.fire(
            {
                title: (form.id) ? ("Club editado correctamente") : ("Club creado correctamente"),
                icon: "success",
                timer: 3000
            })
            .then(() => navigate("/admin/clubes"));
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <main className="container py-5" style={{maxWidth: "50rem"}}>
            <div className="mb-3">
                <h2>{(form.id) ? ("Crear club") : ("Editar club")}</h2>

                <Link to={"/admin/clubes"} className="btn btn-primary">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-chevron-left"></i>
                        <span>Volver</span>
                    </div>
                </Link>
            </div>
            
            <div className="m-auto p-4 bg-white border rounded-3 shadow">
                {form &&
                    <form onSubmit={onFormSubmit} autoComplete="off">
                        <div className="mb-3 d-flex flex-column flex-md-row gap-3">
                            <div className="w-100">
                                <label className="form-label" htmlFor="name">Nombre</label>
                                <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.name}</small>
                            </div>

                            <div className="w-100">
                                <label className="form-label" htmlFor="slug">Slug</label>
                                <div className="input-group">
                                    <span className="input-group-text">/clubes/</span>
                                    <input className="form-control" type="text" id="slug" value={form.slug || ""} onChange={handleChange} required/>
                                </div>
                                <small className="text-danger">{errors.slug}</small>
                            </div>
                        </div>

                        <div className="mb-3 d-flex flex-column flex-md-row gap-3">
                            <div className="flex-fill">
                                <label className="form-label" htmlFor="logoUrl">Logo</label>
                                <input className="form-control" type="url" id="logoUrl" value={form.logoUrl || ""} onChange={handleChange} placeholder="https://" required/>
                                <small className="text-danger">{errors.logoUrl}</small>
                            </div>

                            <div className="flex-fill">
                                <label className="form-label" htmlFor="coverUrl">Portada</label>
                                <input className="form-control" type="url" id="coverUrl" value={form.coverUrl || ""} onChange={handleChange} placeholder="https://" required/>
                                <small className="text-danger">{errors.coverUrl}</small>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="address">Dirección</label>
                            <textarea className="form-control" id="address" value={form.address || ""} onChange={handleChange} rows={3} required></textarea>
                            <small className="text-danger">{errors.address}</small>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="ownerEmail">Correo electrónico del propietario</label>
                            <input className="form-control" type="text" id="ownerEmail" value={form.ownerEmail || ""} onChange={handleChange} required/>
                            <small className="text-danger">{errors.ownerEmail}</small>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="planId">Plan</label>
                            <select className="form-select" id="planId" value={form.planId || ""} onChange={handleChange}>
                                <option value="" disabled>-- Seleccionar opción --</option>
                                {roles.map(plan =>
                                {
                                    return (
                                        <option value={plan.id} key={plan.id}>
                                            {(plan.maxCourts === 0) && "Más de 10 canchas"}
                                            {(plan.maxCourts === 1) && "1 cancha"}
                                            {(plan.maxCourts > 1) && `${plan.maxCourts} canchas`}
                                            {` - $ ${plan.finalPrice.toLocaleString()}`}
                                        </option>
                                    );
                                })}
                            </select>
                            <small className="text-danger">{errors.planId}</small>
                        </div>

                        {(form.id == null) && <button type="submit" className="btn btn-success fw-bold">Crear club</button>}
                        {(form.id != null) && <button type="submit" className="btn btn-success fw-bold">Guardar</button>}
                    </form>
                    
                }
            </div>
        </main>
    );
} 