import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import Swal from "sweetalert2";

import {useUser} from "../../contexts/UserContext";


export default function PanelHeader()
{
    const navigate = useNavigate();

    const {managedClub} = useUser();


    useEffect(() =>
    {
        if(!managedClub) 
        {
            navigate("/mis-clubes");
            return;
        }
    }, [managedClub, navigate]);


    // Copiar url del club al portapapeles
    const onCopySlug = () =>
    {
        navigator.clipboard.writeText("reservarcancha.com/clubes/" + managedClub.slug);

        Swal.fire(
        {
            title: "Enlace copiado",
            icon: "success",
            position: "top-start",
            backdrop: false,
            timer: 2000,
            toast: true,
            showConfirmButton: false
        });
    }


    if(!managedClub) return null;

    return (
        <header className="py-4 text-bg-primary">
            <div className="container-xl">
                <div className="d-flex align-items-center justify-content-between gap-4">
                    <div className="hstack gap-4">
                        <div className="d-none d-lg-block">
                            <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePanelSidebar" aria-expanded="false" aria-controls="collapsePanelSidebar">
                                <i className="fa-regular fa-bars"></i>
                            </button>
                        </div>

                        <div className="hstack gap-2">
                            <img className="img-fluid shadow rounded" src={managedClub.logoUrl} alt="" style={{maxHeight: "2rem"}}/>

                            <h2 className="mb-0">{managedClub.name}</h2>
                        </div>
                    </div>

                    <div className="dropdown">
                        <button className="btn btn-primary rounded-circle fs-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-circle-info fa-fw"></i>
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <Link to={"/clubes/" + managedClub.slug} className="dropdown-item">
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <span>Ir al club</span>
                                        <i className="fa-regular fa-arrow-up-right-from-square"></i>  
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={onCopySlug}>
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <span>Copiar enlace</span>
                                        <i className="fa-regular fa-copy"></i>  
                                    </div>
                                </button>
                            </li>
                            {/* <li>
                                <Link to="ayuda" className="dropdown-item">
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <span>Ayuda</span>
                                        <i className="fa-regular fa-question-circle"></i>
                                    </div>
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}