import {useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function StaffForm({staff, roles, modal, onSubmit})
{
    const {managedClub} = useUser();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        if(!staff) return;
        
        setForm({memberEmail: staff.user.email, role: staff.role});
    }, [staff]);


    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/panel/staff/form?clubId=${managedClub.id}`, form).then(() => 
        {
            onSubmit(); // Actualizar el componente padre

            Swal.fire(
            {
                title: (staff) ? ("Miembro editado correctamente") : ("Miembro añadido correctamente"),
                icon: "success",
                confirmButtonText: "Aceptar",
                timer: 2000
            });
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    const removeMember = () =>
    {
        Swal.fire(
        {
            title: "Eliminar miembro",
            text: "Al eliminar este usuario, ya no formará parte del staff del club y no tendrá acceso a las funciones del mismo.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(response =>
        {
            if(response.isConfirmed) // Eliminar staff
            {
                request("DELETE", `/panel/staff/delete?id=${staff.id}`).then(() => 
                {
                    onSubmit();
                    Swal.fire({title: "Miembro del staff eliminado correctamente", icon: "success", confirmButtonText: "Aceptar", timer: 2000});
                })
                .catch(() => {
                    Swal.fire("Oops", "Ocurrió un error y no supimos qué fue. Si el problema persiste, intenta recargando la página.", "error");
                });
            }
        });
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-hidden="true" aria-labelledby="staffLabel">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        <h5 className="mb-0 fw-bold" id="staffLabel">
                            {(staff) ? "Editar miembro" : "Añadir miembro"}
                        </h5>

                        <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">
                        {form &&
                            <form onSubmit={onFormSubmit} autoComplete="off">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="memberEmail">Correo electrónico</label>
                                    <input className="form-control" type="text" id="memberEmail" value={form.memberEmail || ""} onChange={handleChange} required/>
                                    <small className="text-danger">{errors.memberEmail}</small>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="role">Rol</label>
                                    <select className="form-select" id="role" defaultValue={""} value={form.role} onChange={handleChange}>
                                        <option value="" disabled>-- Seleccionar opción --</option>

                                        {roles.map(role =>
                                        {
                                            return (
                                                <option value={role} key={role}>{role}</option>
                                            )
                                        })}
                                    </select>
                                    <small className="text-danger">{errors.role}</small>
                                </div>

                                <hr className="my-3"/>

                                <div className="d-flex justify-content-between gap-3">
                                    <div>
                                        {(staff && staff.id) &&
                                            <button type="button" className="btn btn-outline-danger" onClick={removeMember}>
                                                <i className="fa-regular fa-trash"></i>
                                            </button>
                                        }
                                    </div>
                                    
                                    <div className="d-flex gap-2">
                                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="submit" className="btn btn-success">Guardar</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
