// Añadir 0s en frente de un numero
export function pad(num, places = 2) 
{
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

// Obtener el nombre del día
export function getDayName(fecha)
{
    const diasSemana = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    const dia = new Date(fecha).getDay();
    return diasSemana[dia];
}

export function getDayNameEx(diaId)
{
    const diasSemana = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    return diasSemana[diaId - 1];
}


export function getDayShortName(fecha)
{
    const diasSemana = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];
    const dia = new Date(fecha).getDay();
    return diasSemana[dia];
}

// Obtener el nombre del mes
export function getMonthName(fecha)
{
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const mes = new Date(fecha).getMonth();
    return meses[mes];
}

export function getMonthShortName(fecha)
{
    const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
    const mes = new Date(fecha).getMonth();
    return meses[mes];
}


// Clase que simula la clase "LocalDate" de java
export class LocalDate
{
    constructor(year, month, day) {
        this.date = new Date(year, month - 1, day);
    }

    static now()
    {
        const today = new Date();
        return new LocalDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    }

    static from(isoDate)
    {
        const split = isoDate.split("-");
        return new LocalDate(split[0], split[1], split[2]);
    }

    plusDays(days)
    {
        const newDate = new Date(this.date);
        newDate.setDate(newDate.getDate() + days);
        return new LocalDate(newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate());
    }

    minusDays(days)
    {
        const newDate = new Date(this.date);
        newDate.setDate(newDate.getDate() - days);
        return new LocalDate(newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate());
    }

    getDayOfWeek()
    {
        const newDate = new Date(this.date);
        return newDate.getDay();
    }

    getDate()
    {
        const newDate = new Date(this.date);
        return newDate.getDate();
    }

    getYear()
    {
        const newDate = new Date(this.date);
        return newDate.getFullYear();
    }

    toString()
    {
        const year = this.date.getFullYear();
        const month = this.date.getMonth() + 1;
        const day = this.date.getDate();

        return `${year}-${pad(month, 2)}-${pad(day, 2)}`;
    }

    toShortString() {
        const day = this.date.getDate();
        const month = this.date.getMonth() + 1;
        return `${pad(day, 2)}/${pad(month, 2)}`;
    }
}


// Ocultar todos los modals de bootstrap
export function hideModals()
{
    const modals = document.querySelectorAll(".modal");
    modals.forEach(modal =>
    {
        var instance = window.bootstrap.Modal.getInstance(modal);
        if(instance !== null) instance.hide();
    });
}

export function hideOffcanvas()
{
    const offcanvas = document.querySelectorAll(".offcanvas");
    offcanvas.forEach(offcanva =>
    {
        var instance = window.bootstrap.Offcanvas.getInstance(offcanva);
        if(instance !== null) instance.hide();
    });
}