import {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";

import {hideModals, hideOffcanvas} from "../services/UtilsService";


export default function Layout()
{
    const location = useLocation();

    useEffect(() =>
    {
        hideModals();
        hideOffcanvas();
        window.scrollTo({behavior: "instant", top: 0, left: 0});

        const body = document.querySelector("body");
        body.style = null;

        document.querySelectorAll(".modal-backdrop").forEach(backdrop => {
            backdrop.remove();
        });
    }, [location]);


    return (
        <Outlet></Outlet>
    );
}