import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import moment from "moment";

import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";


export default function SubscriptionPaymentForm({subscription, payment, modal, onSubmit})
{
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        if(payment) setForm({...payment, subscriptionId: subscription.id});
        else setForm({date: moment().format("yyyy-MM-DDTHH:MM"), amount: subscription.plan.montlyPrice, subscriptionId: subscription.id});
    }, [payment, subscription]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/admin/payments/save", form).then(() =>
        {
            hideModals();

            Swal.fire(
            {
                title: (payment) ? ("Pago editado") : ("Pago registrado"),
                icon: "success",
                position: "top-end",
                backdrop: false
            });

            onSubmit();
        })
        .catch(error =>
        {
            const data = error.response.data;
            setErrors(data.errors);
        });
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-hidden="true" aria-labelledby="paymentLabel">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        {(payment) ?
                            <h5 className="mb-0 fw-bold" id="paymentLabel">Editar pago</h5>
                            :
                            <h5 className="mb-0 fw-bold" id="paymentLabel">Registrar pago</h5>
                        }
                        <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="date">Fecha <span className="text-danger">*</span></label>
                                <input className="form-control" type="datetime-local" id="date" value={form.date || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.date}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="amount">Monto <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input className="form-control" type="text" id="amount" value={form.amount || ""} onChange={handleChange} required/>
                                </div>
                                <small className="text-danger">{errors.amount}</small>
                            </div>

                            {(!payment) &&
                                <div className="mb-3">
                                    <span className="form-text">* Al registrar este pago, se añadirá +1 mes de suscripción al club.</span>
                                </div>
                            }
                                
                            <div className="d-flex align-items-center justify-content-end gap-2">
                                <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">Cancelar</button>
                                <button type="submit" className="btn btn-success">{(payment) ? ("Guardar") : ("Registrar")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}