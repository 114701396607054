import React, {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import {useUser} from "../../contexts/UserContext";
import {request} from "../../services/AxiosService";
import ReservationForm from "../../components/panel/ReservationForm";
import FixedReservationEditForm from "../../components/panel/FixedReservationEditForm";
import { hideModals } from "../../services/UtilsService";


export default function FixedReservation()
{
    const {managedClub} = useUser();

    const {id} = useParams();

    const [fixedReservation, setFixedReservations] = useState(null);

    const days =
    [
        {id: 1, name: "Lunes"},
        {id: 2, name: "Martes"},
        {id: 3, name: "Miércoles"},
        {id: 4, name: "Jueves"},
        {id: 5, name: "Viernes"},
        {id: 6, name: "Sábado"},
        {id: 7, name: "Domingo"},
    ];


    // Funcion para obtener todas las reservas segun el club y la fecha
    const fetchFixedReservation = useCallback(() =>
    {
        if(!managedClub || !id) return;
        
        // Obtener datos del club como las reservas hechas en la fecha seleccionada
        request("GET", `/panel/fixed-reservations/${id}`).then(response => {
            setFixedReservations(response.data);
            hideModals();
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedClub, id]);

    useEffect(() =>
    {
        fetchFixedReservation();
    }, [fetchFixedReservation]);

    useEffect(() =>
    {
        if(!managedClub) return;
        
        document.title = `Reservas fijas / Detalles - ${managedClub.name}`;
    }, [managedClub]);


    return (
        <>{(managedClub) &&
            <main>
                <div className="mb-3">
                    <div className="mb-2">
                        <p className="mb-0 fs-4 fw-semibold">Reserva fija</p>
                        <small className="text-muted">Consulta todos los detalles de una reserva fija</small>
                    </div>

                    <Link to="./.." className="btn btn-sm btn-primary fw-semibold">
                        <div className="hstack gap-2">
                            <i className="fa-regular fa-chevron-left"></i>
                            <span>Volver</span>
                        </div>
                    </Link>
                </div>

                {(fixedReservation) &&
                    <div className="row g-4">
                        <div className="col-12 col-md-6">
                            <div className="p-4 bg-white border rounded-3 shadow">
                                <div className="d-flex align-items-center justify-content-between gap-4">
                                    <h3 className="mb-0">General</h3>

                                    <div>
                                        <button className="btn btn-light" data-bs-toggle="modal" data-bs-target="#fixedReservationEditFormModal" title="Editar reserva fija">
                                            <i className="fa-regular fa-pen-square"></i>
                                        </button>

                                        <FixedReservationEditForm fixedReservation={fixedReservation} modal="fixedReservationEditFormModal" onSubmit={fetchFixedReservation}> </FixedReservationEditForm>
                                    </div>
                                </div>

                                <hr/>

                                <div className="mb-3 p-2 vstack gap-2 bg-primary bg-opacity-10 border border-primary rounded-3">
                                    {(fixedReservation.user) ?
                                        <React.Fragment>
                                            <div className="hstack gap-2 fw-semibold">
                                                <i className="fa-solid fa-user fa-fw text-primary"></i>
                                                <span>{fixedReservation.user.name} {fixedReservation.user.surname}</span>
                                            </div>

                                            <div className="hstack gap-2">
                                                <i className="fa-solid fa-envelope fa-fw text-primary"></i>
                                                <span>{fixedReservation.user.email}</span>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="hstack gap-2 fw-semibold">
                                                <i className="fa-solid fa-user fa-fw text-primary"></i>
                                                <span>{fixedReservation.playerName}</span>
                                            </div>

                                            <div className="hstack gap-2">
                                                <i className="fa-solid fa-phone fa-fw text-primary"></i>
                                                {(fixedReservation.playerPhone) ?
                                                    <span>{fixedReservation.playerPhone}</span>
                                                    :
                                                    <span className="text-muted">(Sin especificar)</span>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                    
                                    <div className="hstack gap-2">
                                        <i className="fa-solid fa-note fa-fw text-primary"></i>
                                        {(fixedReservation.notes) ?
                                            <span>{fixedReservation.notes}</span>
                                            :
                                            <span className="text-muted">(Sin notas)</span>
                                        }
                                    </div>
                                </div>

                                <div className="vstack gap-2">
                                    <div className="hstack justify-content-between gap-4">
                                        <p className="mb-0 fw-semibold">Cancha</p>
                                        <p className="mb-0">{fixedReservation.court.name}</p>
                                    </div>

                                    <div className="hstack justify-content-between gap-4">
                                        <p className="mb-0 fw-semibold">Deporte</p>
                                        <p className="mb-0">{fixedReservation.sport.name}</p>
                                    </div>

                                    <div className="hstack justify-content-between gap-4">
                                        <p className="mb-0 fw-semibold">Repite cada</p>
                                        <p className="mb-0">{days[fixedReservation.dayOfWeek].name}</p>
                                    </div>

                                    <div className="hstack justify-content-between gap-4">
                                        <p className="mb-0 fw-semibold">Hora</p>
                                        <p className="mb-0">{fixedReservation.startHour}:00 - {fixedReservation.endHour}:00</p>
                                    </div>

                                    

                                    <div className="hstack justify-content-between gap-4">
                                        <p className="mb-0 fw-semibold">Fecha de inicio</p>
                                        <p className="mb-0">{moment(fixedReservation.startDate).format("ll")}</p>
                                    </div>

                                    <div className="hstack justify-content-between gap-4">
                                        <p className="mb-0 fw-semibold">Finaliza el</p>
                                        <p className="mb-0">{moment(fixedReservation.endDate).format("ll")}</p>
                                    </div>

                                    {(fixedReservation.weeksRemaining >= 0) ?
                                        <div className="d-flex align-items-start justify-content-between gap-2">
                                            <span className="fw-semibold">Vence en</span>
                                            <span>{fixedReservation.weeksRemaining} semanas</span>
                                        </div>
                                        :
                                        <div className="d-flex align-items-start justify-content-between gap-2 fw-semibold text-danger">
                                            <span>Venció hace</span>
                                            <span>{-fixedReservation.weeksRemaining} semas</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="p-4 bg-white border rounded-4 shadow">
                                <div className="mb-3 d-flex align-items-center justify-content-between gap-4">
                                    <h3 className="mb-0">Reservas</h3>
                                </div>

                                <hr />

                                <div className="vstack gap-2">
                                    {fixedReservation.reservations.map(reservation =>
                                    {
                                        const modal = `reservationModal_${reservation.id}`;
                                        const isPast = (new Date() > new Date(reservation.startTime));

                                        return (
                                            <React.Fragment key={reservation.id}>
                                                <button className={(isPast) ? ("btn btn-light") : ("btn btn-primary bg-primary bg-opacity-10 text-primary")} data-bs-toggle="modal" data-bs-target={"#" + modal} title="Información de la reserva">
                                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                                        <div className="hstack gap-2 fw-semibold">
                                                            <i className="fa-regular fa-calendar"></i>
                                                            <span>{moment(reservation.startTime).format("D MMM yyyy")}</span>
                                                        </div>

                                                        <i className="fa-regular fa-exclamation-circle fa-lg"></i>
                                                    </div>
                                                </button>

                                                <ReservationForm reservation={reservation} court={fixedReservation.court} modal={modal} onSubmit={fetchFixedReservation}>

                                                </ReservationForm>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </main>
        }</>
    );
}
