import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";
import CourtForm from "../../components/panel/CourtForm";
import {useUser} from "../../contexts/UserContext";



export default function Courts()
{
    const {managedClub} = useUser();

    const [courts, setCourts] = useState([]);
    const [sports, setSports] = useState([]);


    // Funcion para obtener todas las canchas del club
    const fetchCourts = useCallback(() =>
    {
        if(!managedClub) return;

        request("GET", `/panel/courts?clubId=${managedClub.id}`).then(response => 
        {
            setCourts(response.data);
            hideModals();
        }).catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedClub]);

    // Funcion para obtener la lista de deportes
    const fetchSports = useCallback(() =>
    {
        request("GET", "/sports/").then(response => {
            setSports(response.data);
        }).catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, []);


    useEffect(() =>
    {
        fetchCourts();
        fetchSports();
    }, [fetchCourts, fetchSports]);

    useEffect(() =>
    {
        if(!managedClub) return;
        
        document.title = `Canchas - ${managedClub.name}`;
    }, [managedClub]);


    return (
        <React.Fragment>
            {(managedClub) &&
                <main>
                    {/* {(courts.subscription.state !== "ACTIVE") &&
                        <SubscriptionStatusAlert suscripcion={courts.subscription}/>
                    }

                    <div className="mb-4 d-flex align-items-start justify-content-between gap-2">
                        <h2 className="mb-0">
                            <Link to={`/clubes/${courts.slug}`} className="fs-1 link-dark link-underline link-underline-opacity-0 link-underline-opacity-75-hover">{courts.name}</Link>
                        </h2>

                        <Navbar> </Navbar>
                    </div>

                    <hr /> */}

                    <div className="mb-3">
                        <p className="mb-0 fs-4 fw-semibold">Canchas</p>
                        <small className="text-muted">Edita las características de tus canchas, tales como el nombre, precios, deporte que se practica, etc. Cada cancha tiene sus propias características y es independiente de las demás.</small>
                    </div>

                    <div className="mb-3">
                        <button type="button" className="btn btn-primary btn-icon" data-bs-toggle="modal" data-bs-target={`#courtModal_new`}>
                            <div className="hstack gap-2">
                                <i className="fa-regular fa-plus"></i>
                                <span>Añadir cancha</span>
                            </div>
                        </button>

                        <CourtForm court={null} sports={sports} modal={`courtModal_new`} onSubmit={fetchCourts}>
                                    
                        </CourtForm>
                    </div>
                
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2 row-cols-lg-3 row-cols-xxl-4 align-items-start g-4">
                        {courts.map((court, courtIndex) =>
                        {
                            const modal = `courtModal_${courtIndex}`;
                            const accordion = `courtSports${courtIndex}`;

                            return (
                                <div className="col" key={court.id}>
                                    <div className="text-bg-light border rounded shadow text-start overflow-hidden">
                                        <div className="p-3 text-bg-success">
                                            <div className="d-flex align-items-center justify-content-between gap-3">
                                                <div></div>

                                                <h3 className="mb-0 fs-5 fw-bold">{court.name}</h3>

                                                <button className="btn btn-sm btn-light" title="Editar cancha" data-bs-toggle="modal" data-bs-target={"#" + modal}>
                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="p-3 d-flex flex-column gap-3">
                                            <div>
                                                <div className="mb-1 hstack gap-2 text-muted">
                                                    <i className="fa-regular fa-info-circle"></i>
                                                    <h4 className="mb-0 hstack gap-1 small fw-semibold text-uppercase text-muted lh-1">Características</h4>
                                                </div>
                                                <p className="mb-0">{court.features}</p>
                                            </div>

                                            <div>
                                                <div className="mb-3 hstack gap-2 text-muted">
                                                    <i className="fa-regular fa-list"></i>
                                                    <h4 className="mb-0 hstack gap-1 small fw-semibold text-uppercase text-muted lh-1">Deportes</h4>
                                                </div>

                                                <div className="accordion" id={accordion}>
                                                    {court.courtSports.map((courtSport, courtSportIndex) =>
                                                    {
                                                        const collapse = `courtSports${courtIndex}sport${courtSportIndex}`;

                                                        return (
                                                            <div className="accordion-item" key={courtSportIndex}>
                                                                <h2 className="accordion-header">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + collapse} aria-expanded="false" aria-controls={collapse}>
                                                                        {courtSport.sport.name}
                                                                    </button>
                                                                </h2>

                                                                <div id={collapse} className="accordion-collapse collapse" data-bs-parent={"#" + accordion}>
                                                                    <div className="accordion-body">
                                                                        <div className="mb-4">
                                                                            <div className="mb-2 hstack gap-2 text-muted">
                                                                                <i className="fa-regular fa-dollar"></i>
                                                                                <h4 className="mb-0 hstack gap-1 small fw-semibold text-uppercase text-muted lh-1">Precios</h4>
                                                                            </div>

                                                                            <table className="table table-light mb-0 align-middle">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Duración</th>
                                                                                        <th>Precio</th>
                                                                                        {(courtSport.depositPercent) &&
                                                                                            <th>Seña</th>
                                                                                        }
                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody>
                                                                                    {courtSport.prices.map((price, priceIndex) =>
                                                                                    {
                                                                                        return (
                                                                                            <tr key={priceIndex}>
                                                                                                <td className="hstack gap-2">
                                                                                                    <i className="fa-regular fa-stopwatch text-primary"></i>
                                                                                                    <span>{price.duration}m</span>
                                                                                                </td>
                                                                                                <td className="fw-semibold">$ {price.price.toLocaleString()}</td>
                                                                                                {(courtSport.depositPercent) &&
                                                                                                    <th>$ {price.depositAmount.toLocaleString()}</th>
                                                                                                }
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                        <div>
                                                                            <div className="mb-1 hstack gap-2 text-muted">
                                                                                <i className="fa-regular fa-money-check-dollar"></i>
                                                                                <h4 className="mb-0 hstack gap-1 small fw-semibold text-uppercase text-muted lh-1">Seña/adelanto</h4>
                                                                            </div>
                                                                            <p className="mb-0">
                                                                                {(courtSport.depositPercent) ?
                                                                                    <span className="fw-semibold">%{courtSport.depositPercent}</span>
                                                                                    :
                                                                                    <span className="text-muted">Sin seña</span>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    
                                                </div>


                                                {/* <p className="mb-0 hstack gap-2">
                                                    <i className={`fa-regular ${court.sport.icon}`}></i>
                                                    <span>{court.sport.name}</span>
                                                </p> */}
                                            </div>

                                            {/* <div>
                                                <h4 className="mb-1 small fw-semibold text-uppercase text-muted"><i className="fa-regular fa-list"></i> <span>Precios</span></h4>
                                                {court.prices.map(price =>
                                                {
                                                    return (
                                                        <p className="mb-0" key={price.id}>
                                                            {price.duration} minutos - $ {price.price.toLocaleString()}
                                                        </p>
                                                    );
                                                })}
                                            </div>

                                            <div>
                                                <h4 className="mb-1 small fw-semibold text-uppercase text-muted"><i className="fa-regular fa-money-check-dollar"></i> <span>Seña</span></h4>
                                                <p className="mb-0">
                                                    {(court.depositPercent) ?
                                                        <span>{court.depositPercent}%</span>
                                                        :
                                                        <span className="text-muted">Sin seña</span>
                                                    }
                                                </p>
                                            </div>

                                            <div>
                                                <h4 className="mb-1 small fw-semibold text-uppercase text-muted">Deporte</h4>
                                                <p className="mb-0 hstack gap-2">
                                                    <i className={`fa-regular ${court.sport.icon}`}></i>
                                                    <span>{court.sport.name}</span>
                                                </p>
                                            </div> */}
                                        </div>
                                    </div>

                                    <CourtForm court={court} sports={sports} modal={modal} onSubmit={fetchCourts}>
                                        
                                    </CourtForm>
                                </div>
                            );
                        })}
                    </div>
                </main>
            }
        </React.Fragment>
    );
} 