import {Outlet} from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ProtectedRoute from "../contexts/ProtectedRoute";
import NotFound from "../views/public/NotFound";
import Sidebar from "../components/profile/Sidebar";
import Reservations from "../views/profile/Reservations";
import Profile from "../views/profile/Account";


const ProfileLayout = () => (
    <>
        <Header/>

        <div className="container-xl my-4">
            <div className="d-flex flex-column flex-lg-row">
                <div className="d-none d-lg-block">
                    <Sidebar></Sidebar>
                </div>

                <div className="flex-fill" style={{minWidth: 0}}>
                    <Outlet/>
                </div>
            </div>
        </div>

        <Footer/>
    </>
);

const profileRoutes = 
[
    {
        path: "/perfil/",
        element: <ProtectedRoute role={"USER"}/>,
        children:
        [{
            element: <ProfileLayout/>,
            errorElement: <NotFound/>,
            children:
            [
                {path: "cuenta", element: <Profile/>},
                {path: "reservas", element: <Reservations/>}
            ]
        }]
    }
];



export default profileRoutes;
