import axios from "axios";


const backendUrl = process.env.REACT_APP_BACKEND_URL;

axios.defaults.baseURL = backendUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";


export function request(method, url, data = {})
{
    const JWT = localStorage.getItem("JWT") || null;
    const bearer = (JWT) ? (`Bearer ${JWT}`) : (null);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return axios(
    {
        method: method,
        headers: 
        {
            Authorization: bearer, 
            "Client-Timezone":  timeZone
        },
        url: url,
        data: data
    });
}
