import React, { useEffect, useState } from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";


export default function MercadoPagoAuth()
{
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const [club, setClub] = useState(null);


    useEffect(() =>
    {
        const code = params.get("code");
        const clubId = params.get("state");

        request("POST", `/panel/mercadopago-auth?code=${code}&clubId=${clubId}`).then(response => {
            setClub(response.data);
        })
        .catch(error =>
        {
            const msg = error.response.data;

            Swal.fire("Ocurrió un error", (msg) ? (msg) : ("No pudimos identificar el error"), "error")
            .then(() =>
            {
                navigate("/");
            });
        });
    }, [params, navigate]);


    useEffect(() =>
    {
        if(!club) return;

        Swal.fire(
        {
            title: "Operación exitosa",
            text: "Vinculaste tu cuenta de MercadoPago correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
        })
        .then(() => {
            navigate(`/panel/${club.slug}/configuracion`);
        });
    }, [club, navigate]);

    return (
        <React.Fragment>
            {(club) ?
                <Link to={`/panel/${club.slug}/configuracion`} className="btn btn-primary">Volver al panel</Link>
                :
                <Link to={"/"} className="btn btn-primary">Volver al inicio</Link>
            }
        </React.Fragment>
    );
}