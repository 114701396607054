import Reservations from "../views/panel/Reservations";
import Courts from "../views/panel/Courts";
import Schedules from "../views/panel/Schedules";
import Subscription from "../views/panel/Subscription";
import Staff from "../views/panel/Staff";
import Configuration from "../views/panel/Configuration";
import NotFound from "../views/public/NotFound";
import {PanelLayout} from "./layouts";
import ProtectedRoute from "../contexts/ProtectedRoute";
import MercadoPagoAuth from "../views/panel/MercadoPagoAuth";
import FixedReservations from "../views/panel/FixedReservations";
import FixedReservation from "../views/panel/FixedReservation";


const panelRoutes = 
[
    {
        path: "/panel/",
        element: <ProtectedRoute role={"CLUBSTAFF"}/>,
        children:
        [{
            element: <PanelLayout/>,
            errorElement: <NotFound/>,
            children:
            [
                {path: "reservas", element: <Reservations/>},
                {path: "reservas-fijas", element: <FixedReservations/>},
                {path: "reservas-fijas/:id", element: <FixedReservation/>},
                {path: "canchas", element: <Courts/>},
                {path: "horarios", element: <Schedules/>},
                {path: "suscripcion", element: <Subscription/>},
                {path: "staff", element: <Staff/>},
                {path: "configuracion", element: <Configuration/>},
                {path: "mercadopago-auth", element: <MercadoPagoAuth/>}
            ]
        }]
    }
];

export default panelRoutes;
