import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import { hideModals } from "../../services/UtilsService";


export default function FixedReservationModal({fixedReservation, court, modal})
{
    const days =
    [
        {id: 1, name: "Lunes"},
        {id: 2, name: "Martes"},
        {id: 3, name: "Miércoles"},
        {id: 4, name: "Jueves"},
        {id: 5, name: "Viernes"},
        {id: 6, name: "Sábado"},
        {id: 7, name: "Domingo"},
    ];
    
    return (
        <div className="modal" id={modal} tabIndex="-1" aria-labelledby="reservaLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title">Datos de la reserva fija</h5>

                            <div className="hstack gap-2 text-muted">
                                <i className="fa-regular fa-court-sport"></i>
                                <span>{court.name}</span>
                            </div>
                        </div>

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                        <div className="mb-4">
                            <h3 className="small fw-semibold">General</h3>

                            <div className="vstack gap-1">
                                <div className="row">
                                    <div className="col-5 text-muted">Repite cada</div>
                                    <div className="col">{days[fixedReservation.dayOfWeek - 1].name}</div>
                                </div>

                                <div className="row">
                                    <div className="col-5 text-muted">Hora</div>
                                    <div className="col">{fixedReservation.startHour}:00 - {fixedReservation.endHour}:00</div>
                                </div>

                                <div className="row">
                                    <div className="col-5 text-muted">Deporte</div>
                                    <div className="col">{fixedReservation.sport.name}</div>
                                </div>

                                <div className="row">
                                    <div className="col-5 text-muted">Inicia el</div>
                                    <div className="col">{moment(fixedReservation.startDate).format("D [de] MMM yyyy")}</div>
                                </div>

                                <div className="row">
                                    <div className="col-5 text-muted">Finaliza el</div>
                                    <div className="col">{moment(fixedReservation.endDate).format("D [de] MMM yyyy")}</div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className="small fw-semibold">Datos del jugador</h3>

                            <div className="vstack gap-1">
                                {(fixedReservation.user) ?
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-5 text-muted">Jugador</div>
                                            <div className="col">{fixedReservation.user.name} {fixedReservation.user.surname}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5 text-muted">Correo electrónico</div>
                                            <div className="col">{fixedReservation.user.email}</div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-5 text-muted">Jugador</div>
                                            <div className="col">{fixedReservation.playerName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5 text-muted">Teléfono</div>
                                            <div className="col">
                                                {(fixedReservation.playerPhone) ?
                                                    <span>{fixedReservation.playerPhone}</span>
                                                    :
                                                    <span className="text-muted">(Sin especificar)</span>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                <div className="row">
                                    <div className="col-5 text-muted">Notas</div>
                                    <div className="col">
                                        {(fixedReservation.notes) ?
                                            <span>{fixedReservation.notes}</span>
                                            :
                                            <span className="text-muted">Vacío</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="d-flex align-items-end justify-contend-end gap-2">
                            <button className="btn btn-light" type="button" data-bs-dismiss="modal">Aceptar</button>
                            <Link to={`/panel/reservas-fijas/${fixedReservation.id}`} className="btn btn-primary" onClick={() => hideModals()}>Ver detalles</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
