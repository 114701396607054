import ReservationDetails from "../views/public/ReservationDetails";
import Signup from "../views/public/Signup";
import Club from "../views/public/Club";
import NotFound from "../views/public/NotFound";
import Login from "../views/public/Login";
import {PublicLayout} from "./layouts";
import MyClubs from "../views/public/MyClubs";


const publicRoutes = 
[
    {
        element: <PublicLayout/>,
        errorElement: <NotFound/>,
        children:
        [
            {path: "login", element: <Login/>},
            {path: "signup", element: <Signup/>},
            {path: "clubes/:slug", element: <Club/>},
            {path: "reservar", element: <ReservationDetails/>},
            {path: "mis-clubes", element: <MyClubs/>},
            {path: "404", element: <NotFound/>}
        ]
    }
];

export default publicRoutes;