import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {GoogleLogin} from "@react-oauth/google";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";
import Swal from "sweetalert2";


export default function Login()
{
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const {Authenticate, redirectPath} = useUser();


    useEffect(() =>
    {
        document.title = "Iniciar sesión | Reservar Cancha"
    }, []);


    // Form auth
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/auth/login", form).then(response =>
        {
            const JWT = response.data;
            Authenticate(JWT);
            navigate(redirectPath);
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    // Google auth
    const googleAuthSuccess = payload =>
    {
        // Enviar las credenciales al backend y obtener un token
        request("POST", "/auth/google", payload).then(response =>
        {
            // Autenticacion correcta
            const JWT = response.data;
            Authenticate(JWT);
            navigate(redirectPath);
        })
        .catch(error =>
        {
            Swal.fire("Oops", "Ocurrió un error al iniciar sesión, intenta otra vez", "error");
        });
    };


    return (
        <main className="container my-5" style={{maxWidth: "30rem"}}>
            <div>
                <div className="mb-3 text-center">
                    <h2 className="display-6">Inicia sesión</h2>
                </div>

                <div className="d-flex justify-content-center">
                    <GoogleLogin useOneTap onSuccess={googleAuthSuccess}>
                        
                    </GoogleLogin>
                </div>
            </div>

            <hr className="my-4"/>

            <div className="mb-3 text-center">
                <p className="mb-0 text-muted">O ingresa con tus credenciales</p>
            </div>
            
            <div className="p-4 bg-white border rounded-3 shadow">
                <form onSubmit={onFormSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">Correo electrónico <span className="text-danger">*</span></label>
                        <input className="form-control" type="email" id="email" onChange={handleChange} placeholder="Tu correo electrónico" required/>
                        <small className="text-danger">{errors.email}</small>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="password">Contraseña <span className="text-danger">*</span></label>
                        <input className="form-control" type="password" id="password" onChange={handleChange} required/>
                        <small className="text-danger">{errors.password}</small>
                    </div>
                    
                    <button type="submit" className="btn btn-primary fw-bold w-100">Iniciar sesión</button>

                    <hr className="my-4"></hr>

                    <div className="d-flex flex-wrap justify-content-center align-items-center gap-2">
                        <p className="mb-0">¿No tienes una cuenta?</p>
                        <Link to={"/signup"} className="btn btn-success fw-bold">Regístrate</Link>
                    </div>
                </form>
            </div>
        </main>
    )
}
