import {useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {pad} from "../../services/UtilsService";
import {useUser} from "../../contexts/UserContext";


export default function ScheduleForm({schedule, day, modal, onSubmit})
{
    const {managedClub} = useUser();

    const [timeSlots, setTimeSlots] = useState([]);
    const [form, setForm] = useState(null);
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        setForm({...schedule, dayId: day.id});

        let timeSlotsEx = [];
        for(let i = 0; i < 24; i++) {
            timeSlotsEx.push(i);
        }
        setTimeSlots(timeSlotsEx);
    }, [schedule, day]);


    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/panel/schedules/form?clubId=${managedClub.id}`, form).then(() => {
            onSubmit(); // Actualizar el componente padre
        }).catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    const eliminarHorario = () =>
    {
        if(window.confirm("¿Deseas eliminar este horario? Esta acción es irreversible"))
        {
            request("DELETE", `/panel/schedules/delete?id=${schedule.id}`).then(() => {
                onSubmit();
            })
            .catch(() => {
                Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
            });
        }
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-hidden="true" aria-labelledby="horarioLabel">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        <h5 className="mb-0" id="horarioLabel">{day.name}</h5>
                        <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">
                        {form &&
                            <form onSubmit={onFormSubmit} autoComplete="off" noValidate>
                                <div className={"mb-3 d-flex align-items-center justify-content-between gap-3"}>
                                    <label className="mb-0 form-label" htmlFor="openingHour">Horario de apertura</label>

                                    <div>
                                        <select className="form-select w-auto" id="openingHour" value={form.openingHour || ""} onChange={handleChange} required>
                                            <option value="" disabled>--:--</option>
                                            {timeSlots.map(timeSlot =>
                                            {
                                                return (
                                                    <option value={timeSlot} key={timeSlot}>{pad(timeSlot)}:00</option>
                                                );
                                            })}
                                        </select>
                                        <small className="text-danger">{errors.openingHour}</small>
                                    </div>
                                </div>

                                <div className={"mb-3 d-flex align-items-center justify-content-between gap-3"}>
                                    <label className="mb-0 form-label" htmlFor="closingHour">Horario de cierre</label>

                                    <div>
                                        <select className="form-select w-auto" id="closingHour" value={form.closingHour || ""} onChange={handleChange} required>
                                            <option value="" disabled>--:--</option>
                                            {timeSlots.map(hora =>
                                            {
                                                return (
                                                    <option value={hora} key={hora}>{pad(hora)}:00</option>
                                                );
                                            })}
                                        </select>
                                        <small className="text-danger">{errors.closingHour}</small>
                                    </div>
                                </div>

                                <hr className="my-3"/>

                                <div className="d-flex justify-content-between gap-4">
                                    <div>
                                        {form.id &&
                                            <button type="button" className="btn btn-outline-danger" onClick={eliminarHorario}>
                                                <i className="fa-regular fa-trash"></i>
                                            </button>
                                        }
                                    </div>

                                    <div className="d-flex gap-2">
                                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="submit" className="btn btn-success">Guardar</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
