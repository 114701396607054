import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import StaffForm from "../../components/panel/StaffForm";
import {hideModals} from "../../services/UtilsService";
import {useUser} from "../../contexts/UserContext";


export default function Staff()
{
    const {managedClub} = useUser();

    const [staff, setStaff] = useState(null);
    const [roles, setRoles] = useState([]);


    const fetchStaff = useCallback(() =>
    {
        if(!managedClub) return;

        request("GET", `/panel/staff?clubId=${managedClub.id}`).then(response => {
            setStaff(response.data);
            hideModals();
        }).catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedClub]);

    const fetchRoles = useCallback(() =>
    {
        request("GET", "/panel/roles").then(response => {
            setRoles(response.data);
        });
    }, []);


    useEffect(() =>
    {
        fetchStaff();
        fetchRoles();
    }, [fetchStaff, fetchRoles]);


    useEffect(() =>
    {
        if(!managedClub) return;
        
        document.title = `Staff - ${managedClub.name}`;
    }, [managedClub]);


    return (
        <React.Fragment>
            {(managedClub) &&
                <main>
                    {/* {(staff.subscription.state !== "ACTIVE") &&
                        <SubscriptionStatusAlert suscripcion={staff.subscription}/>
                    }

                    <div className="mb-4 d-flex align-items-start justify-content-between gap-2">
                        <h2 className="mb-0">
                            <Link to={`/clubes/${staff.slug}`} className="fs-1 link-dark link-underline link-underline-opacity-0 link-underline-opacity-75-hover">{staff.name}</Link>
                        </h2>

                        <Navbar> </Navbar>
                    </div>

                    <hr /> */}

                    <div className="mb-3">
                        <p className="mb-0 fs-4 fw-semibold">Staff</p>
                        <small className="text-muted">Usuarios que son parte del staff del club</small>
                    </div>


                    {/*  */}
                    {(staff) &&
                        <div className="row g-4">
                            <div className="col-12 col-xl">
                                <div className="p-4 bg-white rounded shadow">
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-primary btn-icon" data-bs-toggle="modal" data-bs-target={`#memberModal_new`}>
                                            <div className="hstack gap-2">
                                                <i className="fa-regular fa-user-plus"></i>
                                                <span>Añadir miembro</span>
                                            </div>
                                        </button>

                                        <StaffForm member={null} roles={roles} modal={"memberModal_new"} onSubmit={fetchStaff}>
                                                    
                                        </StaffForm>
                                    </div>

                                    <div className="overflow-x-auto">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Correo electrónico</th>
                                                    <th>Rol</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {staff.map(member =>
                                                {
                                                    const modal = `staffModal_${member.id}`;

                                                    return (
                                                        <tr key={member.id}>
                                                            <td className="text-nowrap">
                                                                <div className="hstack gap-2">
                                                                    <i className="fa-solid fa-user text-primary"></i>
                                                                    <span>{member.user.name} {member.user.surname}</span>
                                                                </div>
                                                            </td>
                                                            <td className="text-nowrap">{member.user.email}</td>
                                                            <td className="text-nowrap">{member.role}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-warning" type="button" title="Editar rol" data-bs-toggle="modal" data-bs-target={`#${modal}`}>
                                                                    <i className="fa-regular fa-pen-square"></i>
                                                                </button>

                                                                <StaffForm staff={member} roles={roles} modal={modal} onSubmit={fetchStaff}>

                                                                </StaffForm>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-xl-4">
                                <div className="p-4 bg-white border rounded-4 shadow">
                                    <h3>Lista de roles definidos</h3>

                                    <ul className="mb-0">
                                        <li>Dueño: tiene acceso total a las funciones del club</li>
                                        <li>Propietario: tiene acceso total a las funciones del club, excepto definir roles.</li>
                                        <li>Empleado: puede adminsitrar las reservas y generar cobros.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    }
                </main>
            }
        </React.Fragment>
    );
} 