import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import { useUser } from "../../contexts/UserContext";


export default function Profile()
{
    const navigation = useNavigate();

    const {user} = useUser();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        request("GET", "/profile/").then(response => {
            setForm(response.data);
        })
        .catch(() => {
            navigation("/login");
        });

        document.title = "Mi cuenta | Reservar Cancha";
    }, [navigation]);


    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/profile/", form).then(() => 
        {
            Swal.fire(
            {
                title: "Tus datos fueron editados",
                icon: "success",
                confirmButtonText: "Aceptar"
            });
        })
        .catch(error =>
        {
            if(error.response.data)
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <main>
            <div className="mb-4 text-center text-lg-start">
                <h3 className="mb-0 fs-2">Mi cuenta</h3>
            </div>


            <div className="border rounded-3 shadow overflow-hidden">
                <div className="p-3 text-bg-primary text-center text-lg-start">
                    <h2 className="mb-0 fs-5 fw-semibold">Datos de tu perfil</h2>
                </div>

                <div className="p-4">
                    {form &&
                        <form onSubmit={onFormSubmit}>
                            <div className="mb-3 d-flex flex-column flex-md-row gap-3">
                                <div className="w-100">
                                    <label className="form-label" htmlFor="name">Nombre</label>
                                    <input className={"form-control" + (errors.name ? " is-invalid" : "")} type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                    <small className="text-danger">{errors.name}</small>
                                </div>

                                <div className="w-100">
                                    <label className="form-label" htmlFor="surname">Apellido</label>
                                    <input className={"form-control" + (errors.surname ? " is-invalid" : "")} type="text" id="surname" value={form.surname || ""} onChange={handleChange} required/>
                                    <small className="text-danger">{errors.surname}</small>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="email">Correo electrónico</label>
                                <input className="form-control" type="email" defaultValue={user?.email} disabled/>
                            </div>

                            <div className="text-end">
                                <button type="submit" className="btn btn-primary fw-semibold">Guardar</button>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </main>
    );
} 