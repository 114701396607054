import React, {useEffect, useState} from "react";

import {request} from "../../services/AxiosService";


export default function Home()
{
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);


    useEffect(() =>
    {
        document.title = "Reserva y juega en las mejores canchas de tu ciudad - Reservar Cancha";

        // Obtener planes
        request("GET", "/plans/").then(response => 
        {
            setPlans(response.data);
            setSelectedPlan(response.data[0]);
        });
    }, []);


    return (
        <React.Fragment>
            {/* Introduccion */}
            <main className="container">
                <div className="row flex-md-row-reverse row-cols-1 row-cols-md-2 align-items-center g-4">
                    <div className="col">
                        <div className="text-center text-md-start">
                            <h2 className="mb-3 display-6 fw-bold text-primary">¡La mejor forma de reservar canchas!</h2>

                            <p className="mb-4 fs-3">Haz que tus jugadores reserven en tus canchas de una manera fácil, rápida y completamente automática.</p>

                            <div className="d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                                <a href="#como-funciona" className="btn btn-primary px-3 text-uppercase fw-bold">
                                    Conoce más
                                </a>

                                <a href="#planes" className="btn btn-success px-3 text-uppercase fw-bold">
                                    Prueba 1 mes gratis
                                </a>   
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <img className="w-100" src="https://img.freepik.com/free-vector/futsal-field-with-players_52683-47537.jpg" alt="Imagen de cabecera"/>
                    </div>
                </div>
            </main>


            {/* Cómo funciona */}
            <section className="container-xl my-5 py-5" id="como-funciona">
                <div className="mb-4 text-center">
                    <h2 className="mx-auto display-6 fw-semibold text-center">¿Cómo funciona?</h2>
                </div>

                <div className="mx-auto d-flex flex-column flex-md-row align-items-center justify-content-center gap-1">
                    <div className="w-100">
                        <div className="vstack gap-5">
                            <div className="d-flex align-items-start gap-3">
                                <div className="text-bg-primary rounded-circle d-flex align-items-center justify-content-center" style={{minWidth: "4rem", minHeight: "4rem"}}>
                                    <i className="fa-solid fa-1 fa-xl fa-fw"></i>
                                </div>

                                <div className="mt-3">
                                    <h3 className="mb-1 fs-5 fw-bold">Registramos tu club</h3>
                                    <p className="mb-0 text-muted">Creamos y configuramos una página exclusiva para tu club, con tus canchas, horarios de atención y más características.</p>
                                </div>
                            </div>

                            <div className="d-flex align-items-start gap-3">
                                <div className="text-bg-primary rounded-circle d-flex align-items-center justify-content-center" style={{minWidth: "4rem", minHeight: "4rem"}}>
                                    <i className="fa-solid fa-2 fa-xl fa-fw"></i>
                                </div>

                                <div className="mt-3">
                                    <h3 className="mb-1 fs-5 fw-bold">Tus jugadores reservan online</h3>
                                    <p className="mb-0 text-muted">Compartes un enlace con tus jugadores para que reserven su partido en la fecha, hora y cancha donde quieran jugar.</p>
                                </div>
                            </div>

                            <div className="d-flex align-items-start gap-3">
                                <div className="text-bg-primary rounded-circle d-flex align-items-center justify-content-center" style={{minWidth: "4rem", minHeight: "4rem"}}>
                                    <i className="fa-solid fa-3 fa-xl fa-fw"></i>
                                </div>

                                <div className="mt-3">
                                    <h3 className="mb-1 fs-5 fw-bold text-primary">¡A jugar!</h3>
                                    <p className="mb-0 text-muted">Una vez que tus jugadores reserven, solo tendrán que asistir a jugar en la fecha y hora elegidas. Tú podrás visualizar y gestionar todas estas reservas fácilmente desde un panel administrativo exclusivo para tu club.</p>

                                    <div className="mt-3">
                                        <a href="#planes" className="btn btn-primary fw-bold">Comienza gratis hoy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                    <div className="w-100">
                        <div className="text-center text-md-end">
                            <img className="img-fluid" src="https://img.freepik.com/free-vector/react-concept-illustration_114360-2995.jpg" alt="Persona reservando" style={{height: "40vw", minHeight: "20rem", maxHeight: "30rem"}}/>
                        </div>
                    </div>
                </div>
            </section>


            {/* Planes */}
            {(plans) &&
                <section className="section bg-primary bg-opacity-10 shadow" id="planes">
                    <div className="container-fluid">
                        <div className="mb-5 text-center">
                            <h2 className="display-6 fw-semibold text-center">¡Prueba 1 mes gratis!</h2>
                            <p className="fs-5">
                                Comienza a gestionar tus canchas completamente gratis hoy, sin necesidad de tarjeta de crédito.
                                <br/>
                                Elige el plan que mejor se adapte a vos y contáctanos por WhatsApp.
                            </p>
                        </div>

                        <div className="mx-auto p-4 bg-white text-dark border border-opacity-25 border-2 rounded-3 shadow border-primary" style={{maxWidth: "25rem"}}>
                            <div className="mb-4">
                                <h3 className="fs-6">¿Cuántas canchas tienes?</h3>

                                <div className="dropdown">
                                    <button className="btn bg-white border w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="d-flex align-items-center justify-content-between gap-3">
                                            {(selectedPlan) ?
                                                <div className="hstack gap-2">
                                                    <i className="fa-regular fa-court-sport fa-fw text-primary"></i>
                                                    <span>
                                                        {(selectedPlan.maxCourts === 0) && "10 canchas o más"}
                                                        {(selectedPlan.maxCourts === 1) && "1 cancha"}
                                                        {(selectedPlan.maxCourts > 1) && `${selectedPlan.maxCourts} canchas`}
                                                    </span>
                                                </div>
                                                :
                                                <div>Selecciona una opción</div>
                                            }

                                            <i className="fa-regular fa-chevron-down text-muted"></i>
                                        </div>
                                    </button>

                                    <ul className="dropdown-menu w-100 shadow">
                                        {plans.map(plan =>
                                        {
                                            return (
                                                <li key={plan.id}>
                                                    <button className="dropdown-item" type="button" onClick={() => setSelectedPlan(plan)}>
                                                        <div className="hstack gap-2">
                                                            <i className="fa-regular fa-court-sport fa-fw text-primary"></i>
                                                            <span>
                                                                {(plan.maxCourts === 0) && "10 canchas o más"}
                                                                {(plan.maxCourts === 1) && "1 cancha"}
                                                                {(plan.maxCourts > 1) && `${plan.maxCourts} canchas`}
                                                            </span>
                                                        </div>
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>

                            {(selectedPlan) &&
                                <div>
                                    <div className="mb-2 text-center">
                                        {(selectedPlan.discount) &&
                                            <div className="mb-3 d-flex justify-content-center align-items-center gap-2">
                                                <div>
                                                    <p className="mb-0 text-decoration-line-through text-muted">AR$ {parseInt(selectedPlan.montlyPrice).toLocaleString()}</p>
                                                </div>

                                                <div className="px-2 bg-success bg-opacity-10 rounded fw-semibold text-success text-uppercase">
                                                    {parseInt(selectedPlan.discount)}% off
                                                </div>
                                            </div>
                                        }

                                        <p className="d-flex align-items-end justify-content-center gap-1 text-primary">
                                            <span>AR$</span>
                                            <span className="display-5 fw-bold lh-1">{parseInt(selectedPlan.finalPrice).toLocaleString()}</span>
                                            <span>/mes</span>
                                        </p>
                                    </div>

                                    <a 
                                        href={"http://wa.me/5493644382805?text=" + encodeURIComponent(`¡Hola! Quiero contratar el plan de ${selectedPlan.maxCourts} canchas. ¿Podrías darme más información?`)}
                                        className="w-100 btn btn-primary py-2 fw-semibold text-uppercase shadow-sm" 
                                        target="_blank" 
                                        rel="external noopener noreferrer" 
                                        title="WhatsApp"
                                        >
                                        <div className="hstack justify-content-center gap-2">
                                            <i className="fa-brands fa-whatsapp"></i>
                                            <span>Contratar 1 mes gratis</span>
                                        </div>
                                    </a>

                                    <hr className="my-4 text-primary"/>

                                    <ul className="mb-0 list-unstyled vstack gap-2">
                                        <li className="hstack gap-2">
                                            <i className="fa-solid fa-check text-success"></i>

                                            <span className="fw-bold">
                                                {(selectedPlan.maxCourts === 0) && "¡Canchas ilimitadas!"}
                                                {(selectedPlan.maxCourts === 1) && "1 cancha"}
                                                {(selectedPlan.maxCourts > 1) && `${selectedPlan.maxCourts} canchas`}
                                            </span>
                                        </li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-success"></i> Página exclusiva para tu club</li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-success"></i> Reservas en tiempo real</li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-success"></i> Panel de control moderno</li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-success"></i> Soporte técnico sin costo</li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            }


            {/* Beneficios */}
            <section className="container section">
                <div className="mb-5 text-center">
                    <h2 className="mx-auto display-6 fw-semibold text-center" style={{maxWidth: "60rem"}}>
                        Conoce los beneficios que tiene nuestro software para vos
                    </h2>
                </div>

                <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-evenly g-4">
                    <div className="col">
                        <div className="text-center">
                            <div className="mb-3">
                                <i className="fa-thin fa-clipboard-check display-4 text-primary"></i>
                            </div>
                            <p className="mb-1 fw-bold fs-4 text-primary">Consigue más reservas</p>
                            <p className="mb-0 fw-light fs-5">Tus jugadores podrán reservar en cualquier momento del día y de manera automática, así no perderás ninguna reserva.</p>
                        </div>
                    </div>

                    <div className="col">
                        <div className="text-center">
                            <div className="mb-3">
                                <i className="fa-thin fa-bolt display-4 text-primary"></i>
                            </div>
                            <p className="mb-1 fw-bold fs-4 text-primary">Fácil de usar</p>
                            <p className="mb-0 fw-light fs-5">Diseñamos una interfaz sumamente intuitiva para que tus jugadores reserven rápidamente y sin problemas.</p>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="text-center">
                            <div className="mb-3">
                                <i className="fa-thin fa-sliders display-4 text-primary"></i>
                            </div>
                            <p className="mb-1 fw-bold fs-4 text-primary">100% configurable</p>
                            <p className="mb-0 fw-light fs-5">Configura tus canchas, precios, horarios de atención y más características a tu gusto.</p>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="text-center">
                            <div className="mb-3">
                                <i className="fa-thin fa-shield-check display-4 text-primary"></i>
                            </div>
                            <p className="mb-1 fw-bold fs-4 text-primary">Soporte dedicado</p>
                            <p className="mb-0 fw-light fs-5">Te ayudamos a configurar tu complejo deportivo de principio a fin, con soporte técnico siempre gratis.</p>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
