export default function NotFound()
{
    return (
        <main className="container section">
            <div className="row row-cols-1 row-cols-md-2 flex-md-row-reverse align-items-center g-4">
                <div className="col">
                    <div className="text-center text-md-start">
                        <h2 className="display-6 fw-bold">Oops, página no encontrada</h2>
                        <p className="fs-5 text-muted">La página que quieres visitar no existe</p>
                        <a href="/" className="btn btn-lg btn-primary fw-semibold">Ir a inicio</a>
                    </div>
                </div>

                <div className="col ">
                    <div className="text-end">
                        <img className="img-fluid" src="https://img.freepik.com/free-vector/page-found-concept-illustration_114360-1869.jpg" alt="Imagen de error"/>
                    </div>
                </div>
            </div>
        </main>
    )
}
