import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import { useUser } from "../../contexts/UserContext";


export default function CourtForm({court, sports, modal, onSubmit})
{
    const {managedClub} = useUser();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        if(court) setForm(court);
    }, [court]);


    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/panel/courts/form?clubId=${managedClub.id}`, form).then(response => 
        {
            onSubmit(); // Actualizar el componente padre

            Swal.fire(
            {
                title: (court) ? ("Cancha editada correctamente") : ("Cancha añadida correctamente"),
                icon: "success",
                confirmButtonText: "Aceptar",
                timer: 2000
            });
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    const deleteCourt = () =>
    {
        Swal.fire(
        {
            title: "Eliminar cancha",
            text: "Al eliminar esta cancha, se borrarán todas las reservas asociadas a ella (no afecta a las demás canchas). Esta acción es irreversible.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(response =>
        {
            if(response.isConfirmed) // Eliminar cancha
            {
                request("DELETE", `/panel/courts/delete?id=${court.id}`).then(() => 
                {
                    onSubmit();
                    Swal.fire({title: "Cancha eliminada correctamente", icon: "success", confirmButtonText: "Aceptar", timer: 2000});
                })
                .catch(() => {
                    Swal.fire("Oops", "Ocurrió un error y no supimos qué fue. Si el problema persiste, intenta recargando la página.", "error");
                });
            }
        });
    };

    /*const addItem = (property, nestedProperties = []) => 
    {
        const newItem = nestedProperties.reduce((acc, prop) => 
        {
            acc[prop] = []; // Inicializa cada nestedProperty como un array vacío
            return acc;
        }, {});
    
        const items = (form[property]) ? [...form[property], newItem] : [newItem];
        setForm({...form, [property]: items});
    };*/

    const changeItem = (property, index, key, value) =>
    {
        const items = [...form[property]];
        items[index][key] = value;
        setForm({...form, [property]: items});
    };

    /*const removeItem = (property, index) =>
    {
        let items = [...form[property]];
        items.splice(index, 1);
        setForm({...form, [property]: items});
    };*/

    // Añadir un nuevo elemento al array anidado
    /*const addSubItem = (property, itemIndex, nestedProperties = []) => 
    {
        const items = [...form[property]];
    
        // Recorre cada nestedProperty y añade un subitem a cada una
        nestedProperties.forEach((nestedProperty) => {
            const nestedItems = items[itemIndex]?.[nestedProperty] ? [...items[itemIndex][nestedProperty], {}] : [{}];
            items[itemIndex] = {...items[itemIndex], [nestedProperty]: nestedItems};
        });
    
        setForm({...form, [property]: items});
    };*/

    // Editar un elemento en el array anidado
    const changeSubItem = (property, itemIndex, nestedProperty, nestedIndex, key, value) => 
    {
        const items = [...form[property]];
        const nestedItems = [...items[itemIndex][nestedProperty]];
        nestedItems[nestedIndex][key] = value;
        items[itemIndex] = {...items[itemIndex], [nestedProperty]: nestedItems};
        setForm({...form, [property]: items});
    };

    // Eliminar un elemento del array anidado
    /*const removeSubItem = (property, itemIndex, nestedProperty, nestedIndex) => {
        const items = [...form[property]];
        let nestedItems = [...items[itemIndex][nestedProperty]];
        nestedItems.splice(nestedIndex, 1);
        items[itemIndex] = {...items[itemIndex], [nestedProperty]: nestedItems};
        setForm({...form, [property]: items});
    };*/



    //
    const onAddSport = () =>
    {
        const formEx = {...form};

        const defaultValues =
        {
            durations: [],
            prices: []
        };
        formEx.courtSports = [...formEx.courtSports || [], defaultValues];
        setForm(formEx);
    };

    const onAddDuration = (courtSportIndex) =>
    {
        const formEx = {...form};
        
        formEx.courtSports[courtSportIndex].durations.push({duration: null});
        formEx.courtSports[courtSportIndex].prices = generatePrices(formEx.courtSports[courtSportIndex]);
        setForm(formEx);
    };

    const onUpdateDuration = (courtSportIndex, durationIndex, value) => 
    {
        const formEx = {...form};

        formEx.courtSports[courtSportIndex].durations[durationIndex].duration = value;
        formEx.courtSports[courtSportIndex].prices = generatePrices(formEx.courtSports[courtSportIndex]);
        setForm(formEx);
    };

    const onDeleteDuration = (courtSportIndex, durationIndex) =>
    {
        const formEx = {...form};
        formEx.courtSports[courtSportIndex].durations.splice(courtSportIndex, 1);
        formEx.courtSports[courtSportIndex].prices = generatePrices(formEx.courtSports[courtSportIndex]);
        setForm(formEx);
    };

    const generatePrices = (courtSport) => 
    {
        const {durations} = courtSport;
        const prices = [];
    
        durations.forEach(duration => 
        {
            prices.push(
            {
                duration: duration.duration, // Asegúrate de usar duration.duration
                price: null, // Valor inicial para el precio
            });
        });
        return prices;
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-hidden="true" aria-labelledby="courtLabel">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        <h5 className="mb-0 fw-bold" id="courtLabel">{(court) ? ("Editar cancha") : ("Nueva cancha")}</h5>
                        <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">
                        {form &&
                            <form onSubmit={onFormSubmit} autoComplete="off">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="name">Nombre</label>
                                    <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                    <small className="text-danger">{errors.name}</small>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="features">Características</label>
                                    <input className="form-control" type="text" id="features" value={form.features || ""} onChange={handleChange} required/>
                                    <small className="text-danger">{errors.features}</small>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="courtSports">Deportes</label>
                                    <div>
                                        <small className="text-danger">{errors.courtSports}</small>
                                    </div>
                                    
                                    {form.courtSports && form.courtSports.map((courtSport, courtSportIndex) =>
                                    {
                                        return (
                                            <div className="mb-3 p-3 border rounded-3 shadow" key={courtSportIndex}>
                                                {/* Deporte */}
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="">Deporte</label>

                                                    <select className="form-select" value={courtSport.sportId || ""} onChange={e => changeItem("courtSports", courtSportIndex, "sportId", e.target.value)}>
                                                        <option value="" disabled>-- Seleccionar opción --</option>
                                                        {sports.map(sport =>
                                                        {
                                                            return (
                                                                <option value={sport.id} key={sport.id}>{sport.name}</option>
                                                            );
                                                        })}
                                                    </select>

                                                    <small className="text-danger">{errors[`courtSports[${courtSportIndex}].sportId`]}</small>
                                                </div>

                                                {/* Duraciones */}
                                                <div className="mt-3">
                                                    <label className="form-label">Duraciones</label>
                                                    <div>
                                                        <small className="text-danger">{errors[`courtSports[${courtSportIndex}].durations`]}</small>
                                                    </div>

                                                    {courtSport.durations && courtSport.durations.map((duration, durationIndex) =>
                                                    {
                                                        const inputId = `courtSports${courtSportIndex}.durations${durationIndex}.minutes`;

                                                        return (
                                                            <div className="mb-2" key={durationIndex}>
                                                                <div className="hstack gap-2">
                                                                    <div className="input-group">
                                                                        <span className="input-group-text" htmlFor={inputId}><i className="fa-regular fa-stopwatch"></i></span>
                                                                        <input className="form-control" type="number" id={inputId} value={duration.duration || ""} onChange={e => onUpdateDuration(courtSportIndex, durationIndex, e.target.value)} placeholder="Minutos" required/>
                                                                    </div>

                                                                    <button className="btn btn-sm btn-light" type="button" title="Quitar" onClick={() => onDeleteDuration(courtSportIndex, durationIndex)}>
                                                                        <i className="fa-regular fa-x"></i>
                                                                    </button>
                                                                </div>
                                                                <small className="text-danger">{errors[`courtSports[${courtSportIndex}].durations[${durationIndex}].duration`]}</small>
                                                            </div>
                                                        );
                                                    })}

                                                    <div>
                                                        <button className="btn btn-sm btn-light w-100" type="button" onClick={() => onAddDuration(courtSportIndex)}>
                                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                                <i className="fa-regular fa-plus"></i>
                                                                <span>Añadir duración</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Precios */}
                                                <div className="mt-3">
                                                    <label className="form-label">Precios</label>
                                                    <div>
                                                        <small className="text-danger">{errors[`courtSports[${courtSportIndex}].prices`]}</small>
                                                    </div>

                                                    <table className="table table-transparent align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th>Duración</th>
                                                                <th>Precio</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {courtSport.durations.map((duration, durationIndex) =>
                                                            {
                                                                const priceIndex = durationIndex;

                                                                return (
                                                                    <tr key={durationIndex}>
                                                                        <td>{duration.duration}'</td>
                                                                        <td>
                                                                            <div className="input-group">
                                                                                <span className="input-group-text">$</span>
                                                                                <input className="form-control" type="number" id={priceIndex} value={courtSport.prices[priceIndex].price || ""} onChange={e => changeSubItem("courtSports", courtSportIndex, "prices", priceIndex, "price", e.target.value)} placeholder="Precio" required/>
                                                                            </div>
                                                                            <small className="text-danger">{errors[`courtSports[${courtSportIndex}].prices[${priceIndex}].price`]}</small>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {/* Seña */}
                                                <div className="mt-3">
                                                    <label className="form-label" htmlFor={`courtSports${courtSportIndex}.depositPercent`}>Seña/adelanto</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text">%</span>
                                                        <input className="form-control" type="number" id={`courtSports${courtSportIndex}.depositPercent`} value={courtSport.depositPercent || ""} onChange={e => changeItem("courtSports", courtSportIndex, "depositPercent", e.target.value)} min={0} max={100}/>
                                                    </div>

                                                    <small className="text-danger">{errors[`courtSports[${courtSportIndex}].depositPercent`]}</small>
                                                    <div className="form-text">Introduce un porcentaje si deseas que los jugadores paguen un anticipo para realizar la reserva.</div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <div className="mt-2">
                                        <button className="btn btn-sm btn-success" type="button" onClick={onAddSport}>
                                            <div className="hstack gap-2">
                                                <i className="fa-regular fa-plus"></i>
                                                <span>Añadir deporte</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <small className="text-danger">{errors.main}</small>
                                </div>

                                <hr className="my-3"/>

                                <div className="d-flex justify-content-between gap-3">
                                    <div>
                                        {court &&
                                            <button type="button" className="btn btn-outline-danger" onClick={deleteCourt}>
                                                <i className="fa-regular fa-trash"></i>
                                            </button>
                                        }
                                    </div>
                                    
                                    <div className="d-flex gap-2">
                                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="submit" className="btn btn-success">Guardar</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
