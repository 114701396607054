import Clubs from "../views/admin/Clubs";
import Plans from "../views/admin/Plans";
import ClubForm from "../views/admin/ClubForm";
import Subscription from "../views/admin/Subscription";
import NotFound from "../views/public/NotFound";
import {PublicLayout} from "./layouts";
import ProtectedRoute from "../contexts/ProtectedRoute";
import Users from "../views/admin/Users";
import UserForm from "../views/admin/UserForm";


const adminRoutes = 
[
    {
        path: "/admin",
        element: <ProtectedRoute role={"ADMIN"}/>,
        children:
        [{
            element: <PublicLayout/>,
            errorElement: <NotFound/>,
            children:
            [
                {path: "clubes", element: <Clubs/>},
                {path: "clubes/form", element: <ClubForm/>},
                {path: "suscripciones", element: <Subscription/>},
                {path: "planes", element: <Plans/>},
                {path: "usuarios", element: <Users/>},
                {path: "usuarios/form", element: <UserForm/>}
            ]
        }]
    }
];

export default adminRoutes;