import {Link, useLocation} from 'react-router-dom';


export const links =
[
    {title: "Cuenta",           href: "cuenta",           icon: "fa-clipboard-check"},
    {title: "Mis reservas",     href: "reservas",           icon: "fa-thumbtack"}
];

export default function Sidebar()
{
    const location = useLocation();


    return (
        <div className="vstack gap-2 me-4" style={{minWidth: "15rem"}}>
            {links.map(link =>
            {
                const pathname = "/perfil/" + link.href + "/";
                const active = ((location.pathname + "/").startsWith(pathname));

                const btnClass = (active) ? ("btn-primary fw-semibold") : ("btn-light");
                const iconClass = (active) ? ("fa-solid") : ("fa-thin");

                
                return (
                    <Link to={link.href} className={`btn ${btnClass}`} key={link.href}>
                        <div className="hstack gap-2">
                            <i className={`${iconClass} ${link.icon} fa-fw`}></i>
                            <span>{link.title}</span>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
}