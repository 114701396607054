import moment from "moment";


export default function ReservationCard({reservation, modal})
{
    let dayText = "";
    const hourText = `a las ${moment(reservation.startTime).format("HH:mm")}`;

    const now = moment(new Date()).format("yyyy-MM-DD");
    const startTime = moment(reservation.startTime).format("yyyy-MM-DD");
    const daysDiff = moment(startTime).diff(now , "days");
    
    switch(daysDiff)
    {
        case 0:
            dayText = "Hoy";
            break;
        case 1:
            dayText = "Mañana";
            break;
        case -1:
            dayText = "Ayer";
            break;
        default:
            dayText = moment(startTime).format("dddd[, ] D MMM");
            break;
    }

    return (
        <button className="btn btn-light border rounded-4 text-start" type="button" data-bs-toggle="modal" data-bs-target={"#" + modal}>
            <div className="d-flex align-items-center justify-content-between gap-2">
                <div className="hstack gap-2 overflow-hidden">
                    {reservation.club.logoUrl &&
                        <img src={reservation.club.logoUrl} className="object-fit-contain" style={{width: "5rem", height: "5rem"}} alt="Logo" loading="lazy"/>
                    }

                    <div className="p-2 text-truncate">
                        <h3 className="mb-1 fw-semibold fs-5 text-truncate">{reservation.club.name}</h3>

                        <div className="hstack gap-1 small">
                            <i className={`fa-regular ${reservation.sport.icon} fa-fw text-primary`}></i>
                            <span>{reservation.sport.name}</span>
                        </div>

                        <div className="hstack gap-1 small">
                            <i className="fa-regular fa-calendar fa-fw text-primary"></i>
                            <span>{dayText} {hourText}</span>
                        </div>
                    </div>
                </div>

                <div className="px-2">
                    <i className="fa-solid fa-circle-info fs-5 text-primary" title="Ver más"></i>
                </div>
            </div>
        </button>
    );
}