import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";

import {LocalDate} from "../../services/UtilsService";
import {request} from "../../services/AxiosService";
import moment from "moment";


export default function ReservationForm({reservation, court, date, modal, onSubmit})
{
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [prices, setPrices] = useState([]);


    useEffect(() =>
    {
        let formEx = {courtId: court.id, date};

        if(reservation) 
        {
            const {id, sport, startTime, duration, price, playerName, playerPhone, notes, payment} = reservation;
            formEx = 
            {
                ...formEx,
                id,
                sportId: sport.id,
                date: moment(startTime).format("yyyy-MM-DD"),
                time: moment(startTime).format("HH:mm"),
                duration,
                price,
                paidAmount: (payment) ? (payment.paidAmount) : (null),
                fullyPaid: (payment) ? (payment.isFullyPaid) : (null),
                playerName,
                playerPhone,
                notes
            };
        }
        else
        {
            if(court.courtSports && court.courtSports.length > 0)
            {
                const firstCs = court.courtSports[0];
                const hasPrices = (firstCs.prices && firstCs.prices.length > 0);
                
                const sportId = firstCs.sportId;
                const priceId = (hasPrices) ? (1) : (null);
                const price = (hasPrices) ? (firstCs.prices[0].price) : (null);
                const duration = (hasPrices) ? (firstCs.prices[0].duration) : (null);

                formEx = {...formEx, sportId, priceId, duration, price};
            }
        }

        setForm(formEx);
    }, [reservation, court, date]);


    useEffect(() =>
    {
        if(!form.sportId) return;

        court.courtSports.forEach(courtSport =>
        {
            if(courtSport.sportId === parseInt(form.sportId)) 
            {
                setPrices(courtSport.prices);
                return;
            }
        });
    }, [form.sportId, court.courtSports]);


    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    // Cuando selecciona una opcion de precio en la lista de precios
    const onSelectPrice = e =>
    {
        const priceId = e.target.selectedIndex;
        
        if(priceId === 0) // Precio personalizado
        {
            setForm({...form, priceId, duration: "", price: ""});
        }
        else // Precio pre-establecido
        {
            const price = prices[priceId - 1];
            setForm({...form, priceId, duration: price.duration, price: price.price});
        }
    };

    const changeFullyPaid = e =>
    {
        const {checked} = e.target;
        setForm({...form, fullyPaid: checked});
    }

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/panel/reservations/form", form).then(() => 
        {
            onSubmit(); // Actualizar el componente padre
            setForm({});
            setErrors({});

            Swal.fire(
            {
                position: "top-end",
                icon: "success",
                title: (reservation) ? ("Datos guardados") : ("Hora reservada"),
                showConfirmButton: false,
                backdrop: false,
                timer: 1500
            });
        })
        .catch(error =>
        {   
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    const deleteReservation = () =>
    {
        if(!reservation) return Swal.fire("La reserva no existe", "", "error");

        Swal.fire(
        {
            title: "Confirmar eliminación",
            text: "Confirma que quieres eliminar esta reserva",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
        })
        .then(response =>
        {
            if(response.isConfirmed)
            {
                request("DELETE", `/panel/reservations/delete?id=${reservation.id}`, form).then(() => 
                {
                    Swal.fire(
                    {
                        position: "top-end",
                        icon: "success",
                        title: "Reserva eliminada correctamente",
                        showConfirmButton: false,
                        backdrop: false,
                        timer: 1500
                    });

                    onSubmit();
                })
                .catch(() =>
                {
                    Swal.fire("No se pudo eliminar la reserva", "question");
                });
            }
        });
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-labelledby="reservaLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title">{(reservation) ? ("Datos de la reserva") : ("Nueva reserva")}</h5>
                            <div className="hstack gap-2">
                                <i className="fa-regular fa-court-sport"></i>
                                <span>{court.name}</span>
                            </div>
                        </div>

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-4">
                                <p className="mb-2 small fw-semibold">Datos del partido</p>

                                <div className="mb-3 row align-items-start gx-4">
                                    <div className="col-3 text-end">
                                        <label className="form-label mb-0 pt-2" htmlFor="date">Fecha</label>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="date" id="date" value={form.date || ""} onChange={handleChange} min={LocalDate.now()} max={LocalDate.now().plusDays(7)} required/>
                                        <small className="text-danger">{errors.date}</small>
                                    </div>
                                </div>

                                <div className="mb-3 row align-items-start gx-4">
                                    <div className="col-3 text-end">
                                        <label className="form-label mb-0 pt-2" htmlFor="time">Hora</label>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="time" id="time" value={form.time || ""} onChange={handleChange} required/>
                                        <small className="text-danger">{errors.time}</small>
                                    </div>
                                </div>

                                <div className="mb-3 row align-items-start gx-4">
                                    <div className="col-3 text-end">
                                        <label className="form-label mb-0 pt-2" htmlFor="sportId">Deporte</label>
                                    </div>
                                    <div className="col">
                                        <select className="form-select" id="sportId" value={form.sportId || ""} onChange={handleChange}>
                                            <option value="" disabled>-- Seleccionar opción --</option>
                                            {court.courtSports.map(courtSport =>
                                            {
                                                return (
                                                    <option value={courtSport.sport.id} key={courtSport.id}>{courtSport.sport.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                {(form.sportId) &&
                                    <React.Fragment>
                                        <div className="mb-3 row align-items-center gx-4">
                                            <div className="col-3 text-end">
                                                <label className="form-label mb-0" htmlFor="priceId">Precios</label>
                                            </div>
                                            <div className="col">
                                                <select className="form-select" id="priceId" value={form.priceId || ""} onChange={e => onSelectPrice(e)}>
                                                    <option value="">(Personalizado)</option>
                                                    {prices.map((price, priceIndex) =>
                                                    {
                                                        return (
                                                            <option value={priceIndex + 1} key={price.id}>{price.duration} minutos - $ {price.price.toLocaleString()}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        
                                        {(!form.priceId) &&
                                            <React.Fragment>
                                                <div className="mb-3 row align-items-start gx-4">
                                                    <div className="col-3 text-end">
                                                        <label className="form-label mb-0 pt-2" htmlFor="duration">Duración</label>
                                                    </div>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <span className="input-group-text"><i className="fa-regular fa-clock fa-fw"></i></span>
                                                            <input className="form-control" type="number" id="duration" value={form.duration || ""} onChange={handleChange} placeholder="Minutos" min={10} required/>
                                                        </div>
                                                        <small className="text-danger">{errors.duration}</small>
                                                    </div>
                                                </div>

                                                <div className="mb-3 row align-items-start gx-4">
                                                    <div className="col-3 text-end">
                                                        <label className="form-label mb-0 pt-2" htmlFor="price">Precio</label>
                                                    </div>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <span className="input-group-text"><i className="fa-regular fa-dollar fa-fw"></i></span>
                                                            <input className="form-control" type="number" id="price" value={form.price || ""} onChange={handleChange} placeholder="Precio" min={0} required/>
                                                        </div>
                                                        <small className="text-danger">{errors.price}</small>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </div>

                            <div className="mb-4">
                                <p className="mb-2 small fw-semibold">Datos del jugador</p>

                                <div className="mb-3 row align-items-start gx-4">
                                    <div className="col-3 text-end">
                                        <label className="form-label mb-0 pt-2" htmlFor="playerName">Nombre</label>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" id="playerName" value={form.playerName || ""} onChange={handleChange} placeholder="Juan Perez" required/>
                                        <small className="text-danger">{errors.playerName}</small>
                                    </div>
                                </div>

                                <div className="mb-3 row align-items-start gx-4">
                                    <div className="col-3 text-end">
                                        <label className="form-label mb-0 pt-2" htmlFor="playerPhone">Teléfono</label>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" id="playerPhone" value={form.playerPhone || ""} onChange={handleChange}/>
                                        <small className="text-danger">{errors.playerPhone}</small>
                                    </div>
                                </div>

                                <div className="mb-3 row align-items-start gx-4">
                                    <div className="col-3 text-end">
                                        <label className="form-label mb-0 pt-2" htmlFor="notes">Notas</label>
                                    </div>
                                    <div className="col">
                                        <textarea className="form-control" id="notes" value={form.notes || ""} onChange={handleChange} rows={2}></textarea>
                                        <small className="text-danger">{errors.notes}</small>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-4">
                                <p className="mb-2 small fw-semibold">Registrar pago</p>

                                <div className="mb-3 row align-items-start gx-4">
                                    <div className="col-3 text-end">
                                        <label className="form-label mb-0 pt-2" htmlFor="paidAmount">Seña</label>
                                    </div>
                                    <div className="col">
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                {(form.fullyPaid === true) ?
                                                    <input className="form-control" type="number" id="paidAmount" value={form.price || ""} min={0} max={form.price || 0} disabled/>
                                                    :
                                                    <input className="form-control" type="number" id="paidAmount" value={form.paidAmount || ""} onChange={handleChange} min={0} max={form.price || 0}/>
                                                }
                                            </div>
                                            <small className="text-danger">{errors.paidAmount}</small>
                                            <div className="form-text">Indica si el jugador hizo una seña/adelanto</div>
                                        </div>

                                        <div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id={modal + "_fullyPaid"} value={form.fullyPaid || ""} onChange={changeFullyPaid}/>
                                                <label className="form-check-label" htmlFor={modal + "_fullyPaid"}>Reserva pagada</label>
                                            </div>
                                            <div className="form-text">Marca esta opción si el jugador pagó el total de la reserva</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="d-flex justify-content-between gap-3">
                                <small className="text-danger">{errors.main}</small>

                                <div>
                                    {(reservation) &&
                                        <button type="button" className="btn btn-outline-danger" onClick={deleteReservation}>
                                            <i className="fa-regular fa-trash"></i>
                                        </button>
                                    }
                                </div>

                                <div>
                                    <small className="text-danger">{errors.main}</small>

                                    <div className="d-flex justify-content-end gap-2">
                                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="button" className="btn btn-success" onClick={onFormSubmit}>
                                            {(reservation) ? ("Guardar") : ("Reservar")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
