import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";


export default function FixedReservationEditForm({fixedReservation, modal, onSubmit})
{
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});


    useEffect(() =>
    {
        if(!fixedReservation) return;

        let formEx = {id: fixedReservation.id, notes: fixedReservation.notes};
        if(!fixedReservation.user) formEx = {...formEx, playerName: fixedReservation.playerName, playerPhone: fixedReservation.playerPhone};

        setForm(formEx);
    }, [fixedReservation]);


    // Form
    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/panel/fixed-reservations/edit", form).then(() => 
        {
            onSubmit(); // Actualizar el componente padre
            setForm({});
            setErrors({});

            Swal.fire(
            {
                position: "top-end",
                icon: "success",
                title: "Reserva fija editada",
                showConfirmButton: false,
                backdrop: false,
                timer: 2000
            });
        })
        .catch(error =>
        {   
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    const onDelete = () =>
    {
        Swal.fire(
        {
            title: "¿Estás seguro?",
            text: "Se eliminarán todas las reservas futuras. No se eliminarán las reservas pasadas.",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        })
        .then(response =>
        {
            if(response.isConfirmed) // Eliminar
            {
                request("DELETE", `/panel/fixed-reservations/delete?id=${fixedReservation.id}`).then(() =>
                {
                    hideModals();

                    Swal.fire(
                    {
                        position: "top-end",
                        icon: "success",
                        title: "Reserva fija eliminada correctamente",
                        confirmButtonText: "Aceptar"
                    })
                    .then(() => {
                        navigate(-1);
                    });
                })
            }
        })
    }

    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-labelledby="reservaLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Editar datos de la reserva fija</h5>

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-3">
                                <small className="fw-semibold">Datos del jugador</small>
                            </div>

                            {(fixedReservation.user) ?
                                <React.Fragment>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="playerName">Nombre</label>
                                        <input className="form-control" type="text" id="playerName" defaultValue={fixedReservation.user.name + " " + fixedReservation.user.surname} disabled/>
                                        <small className="text-danger">{errors.playerName}</small>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="playerPhone">Correo electrónico</label>
                                        <input className="form-control" type="text" id="playerPhone" defaultValue={fixedReservation.user.email} disabled/>
                                        <small className="text-danger">{errors.playerPhone}</small>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="playerName">Nombre</label>
                                        <input className="form-control" type="text" id="playerName" value={form.playerName || ""} onChange={handleChange} placeholder="Juan Perez" required/>
                                        <small className="text-danger">{errors.playerName}</small>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="playerPhone">Teléfono</label>
                                        <input className="form-control" type="text" id="playerPhone" value={form.playerPhone || ""} onChange={handleChange}/>
                                        <small className="text-danger">{errors.playerPhone}</small>
                                    </div>
                                </React.Fragment>
                            }

                            <div className="mb-3">
                                <label className="form-label" htmlFor="notes">Notas</label>
                                <textarea className="form-control" id="notes" value={form.notes || ""} onChange={handleChange} rows={2}></textarea>
                                <small className="text-danger">{errors.notes}</small>
                            </div>

                            <hr />

                            <div className="d-flex align-items-center justify-content-between gap-3">
                                <div>
                                    <button className="btn btn-danger" type="button" title="Eliminar reserva fija" onClick={() => onDelete()}>
                                        <i className="fa-regular fa-trash-can"></i>
                                    </button>
                                </div>

                                <div className="hstack gap-2">
                                    <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancelar</button>
                                    <button className="btn btn-success" type="submit">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
