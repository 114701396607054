import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import ClubForm from "../../components/panel/ClubForm";
import {useUser} from "../../contexts/UserContext";


export default function Configuration()
{
    const {managedClub, setManagedClub} = useUser();
    
    const [club, setClub] = useState({});
    const mpAppId = process.env.REACT_APP_MERCADOPAGO_APP_ID;


    const fetchClub = useCallback(() =>
    {
        request("GET", `/panel/configuration?clubId=${managedClub.id}`).then(response => 
        {
            setClub(response.data);
            setManagedClub(response.data); // Actualizar también los datos del club que tiene guardado el usuario 
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, []);


    useEffect(() =>
    {
        if(!managedClub) return;

        fetchClub();
    }, [fetchClub]);

    useEffect(() =>
    {
        if(!managedClub) return;

        document.title = `Configuración - ${managedClub.name}`;
    }, [managedClub]);

    return (
        <React.Fragment>
            {(managedClub) &&
                <main>
                    <div className="mb-3">
                        <p className="mb-0 fs-4 fw-semibold">Configuración</p>
                        <small className="text-muted">Modifica los datos generales de tu club.</small>
                    </div>


                    {/*  */}
                    <div className="row g-4" style={{maxWidth: "80rem"}}>
                        <div className="col-12 col-md-7">
                            <div className="bg-white p-4 border rounded-3 shadow-sm">
                                <div className="mb-4">
                                    <h3 className="mb-0 fs-5 fw-bold">General</h3>
                                    <small className="text-muted">Información general del club, visible para los jugadores</small>
                                </div>

                                <ClubForm club={club} onSubmit={fetchClub}> </ClubForm>
                            </div>
                        </div>

                        <div className="col-12 col-md">
                            <div className="bg-white p-4 border rounded-3 shadow-sm">
                                <div className="mb-4">
                                    <h3 className="fs-5 fw-bold">MercadoPago</h3>
                                </div>

                                {(club.mercadoPagoAuth) ?
                                    <React.Fragment>
                                        <div className="mb-3 hstack gap-2 text-success">
                                            <i className="fa-regular fa-check"></i>
                                            <span className="fw-bold">Cuenta vinculada</span>
                                        </div>

                                        <p className="mb-0">Ten en cuenta que MercadoPago puede aplicar ciertas comisiones por cada transacción, las cuales son ajenas a nosotros.</p>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <p>Vincula tu cuenta de MercadoPago para habilitar las reservas con señas.</p>

                                        <a href={`https://auth.mercadopago.com.ar/authorization?client_id=${mpAppId}&response_type=code&platform_id=mp&state=${club.id}&redirect_uri=https://www.reservarcancha.com/panel/mercadopago-auth`} className="btn btn-primary fw-semibold" target="_blank" rel="external nooppener noreferrer">
                                            <div className="hstack justify-content-center gap-2">
                                                <img src="/img/svg/mercadopago.svg" alt="MercadoPago" style={{width: "2rem", height: "2rem"}}/>
                                                <span>Vincular Mercado Pago</span>
                                            </div>
                                        </a>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            }
        </React.Fragment>
    );
} 