import React from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";
import PAYMENT_TYPE from "../../services/PAYMENT_TYPE";


export default function ReservationModal({reservation, modal, onDelete})
{
    const navigate = useNavigate();


    // Cuando hace click en el boton para cancelar la reserva
    const onReservationCancel = () =>
    {
        // Mostrar alerta
        Swal.fire(
        {
            title: "¿Estás seguro?",
            text: "Puedes volver a reservar cuando quieras.",
            cancelButtonText: "No, salir",
            confirmButtonText: "Cancelar reserva",
            showCancelButton: true,
        })
        .then(response =>
        {
            if(response.isConfirmed)
            {
                request("DELETE", `/profile/reservations/cancel?id=${reservation.id}`).then(() => {
                    onDelete(); // Enviar el evento al elemento padre
                })
                .catch(error => 
                {
                    const msg = error.response.data;
                    Swal.fire("Ocurrió un error", msg, "error").then(() =>
                    {   
                        navigate("/reservas");
                        hideModals();
                    });
                });
            }
        });
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-labelledby="reservaLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        <h5 className="modal-title">Datos de tu reserva</h5> 

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                        <div className="mb-3 d-flex align-items-center justify-content-between gap-2">
                            <div className="d-flex align-items-center gap-3">
                                {reservation.club.logoUrl &&
                                    <div className="p-2 bg-dark rounded" style={{width: "5rem", height: "5rem"}}>
                                        <img src={reservation.club.logoUrl} className="w-100 h-100 object-fit-contain" alt="Logo"/>
                                    </div>
                                }

                                <div>
                                    <h2 className="mb-1 fs-4 fw-bold">
                                        <Link to={`/clubes/${reservation.club.slug}`} className="link-dark link-underline-opacity-0 link-underline-opacity-75-hover">{reservation.club.name}</Link>
                                    </h2>
                                    <p className="mb-0 text-muted">
                                        <i className="fa-regular fa-location-dot fa-sm"></i> <span>{reservation.club.address}</span>
                                    </p>
                                </div>
                            </div>

                            <div>
                                <Link to={`/clubes/${reservation.club.slug}#ubicacion`} className="btn btn-primary" title="Ver en el mapa">
                                    <i className="fa-regular fa-map-location-dot"></i>
                                </Link>
                            </div>
                        </div>

                        <div>
                            <div className="mb-3 d-flex align-items-center gap-2">
                                <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                    <i className="fa-regular fa-calendar fs-4 text-primary"></i>
                                </div>

                                <div>
                                    <p className="mb-0 fw-semibold">Fecha</p>
                                    <p className="mb-0 small">{moment(reservation.startTime).format("dddd[,] DD MMM [a las] HH:mm")}</p>
                                </div>
                            </div>

                            <div className="mb-3 d-flex align-items-center gap-2">
                                <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                    <i className={`fa-regular ${reservation.sport.icon} fs-4 text-primary`}></i>
                                </div>

                                <div>
                                    <p className="mb-0 fw-semibold">Deporte</p>
                                    <p className="mb-0 small link-icon">{reservation.sport.name}</p>
                                </div>
                            </div>

                            <div className="mb-3 d-flex align-items-center gap-2">
                                <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                    <i className="fa-regular fa-stopwatch fs-4 text-primary"></i>
                                </div>

                                <div>
                                    <p className="mb-0 fw-semibold">Duración</p>
                                    <p className="mb-0 small">{reservation.duration} minutos</p>
                                </div>
                            </div>

                            <div className="mb-3 d-flex align-items-center gap-2">
                                <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                    <i className="fa-regular fa-dollar-circle fs-4 text-primary"></i>
                                </div>

                                <div>
                                    <p className="mb-0 fw-semibold">Total</p>
                                    <p className="mb-0 fw-bold text-success">$ {reservation.price.toLocaleString()}</p>
                                </div>
                            </div>

                            <div className="alert alert-success" role="alert">
                                {(reservation.paymentType === PAYMENT_TYPE.PRESENTIAL) &&
                                    <React.Fragment>
                                        <h4 className="alert-heading fw-bold small text-uppercase">Pago presencial</h4>
                                        <p className="mb-0">Pagás el total de la reserva cuando llegues al complejo deportivo.</p>
                                    </React.Fragment>
                                }
                                {(reservation.paymentType === PAYMENT_TYPE.ONLINE) &&
                                    <React.Fragment>
                                        <h4 className="alert-heading fw-bold small text-uppercase">Reserva pagada</h4>
                                        <p className="mb-0">Sólo tendrás que presentarte a jugar.</p>
                                    </React.Fragment>
                                }
                                {(reservation.paymentType === PAYMENT_TYPE.PARTIAL) &&
                                    <React.Fragment>
                                        <h4 className="alert-heading fw-bold small text-uppercase">Pagaste una seña/adelanto</h4>
                                        <p className="mb-0">El resto lo pagarás al llegar al complejo deportivo.</p>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="alert alert-primary" role="alert">
                                <h4 className="alert-heading fw-bold small text-uppercase">Política de cancelación</h4>

                                {(reservation.paymentType === PAYMENT_TYPE.PRESENTIAL) ?
                                    <p className="mb-0">Puedes cancelar hasta las {moment(reservation.cancelationLimit).format("HH:mm [del] D MMM")} Si cancelas luego de esa fecha, el complejo podría restringirte temporalmente de hacer nuevas reservas.</p>
                                    :
                                    <p className="mb-0">Cancelación gratuita antes de las {moment(reservation.cancelationLimit).format("HH:mm [del] D MMM")} Si cancelas luego de esa fecha, tu reserva no podrá ser reembolsada.</p>
                                }
                            </div>

                            <hr className="my-3"/>

                            <div className="text-end">
                                {(new Date() < new Date(reservation.startTime)) ?
                                    <button className="btn btn-warning fw-semibold" onClick={onReservationCancel}>
                                        Cancelar reserva
                                    </button>
                                    :
                                    <Link to={`/clubes/${reservation.club.slug}?deporteId=${reservation.sport.id}`} className="btn btn-success fw-semibold">
                                        <div className="hstack gap-2">
                                            <i className="fa-regular fa-calendar-plus"></i>
                                            <span>Reservar otra vez</span>
                                        </div>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
