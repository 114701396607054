import React from 'react';
import ReactDOM from 'react-dom/client';

import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {UserProvider} from './contexts/UserContext';

import indexRoute from './routes/indexRoute';
import publicRoutes from './routes/publicRoutes';
import adminRoutes from './routes/adminRoutes';
import panelRoutes from './routes/panelRoutes';
import Layout from './views/Layout';
import profileRoutes from './routes/profileRoutes';
//import reportWebVitals from './reportWebVitals';


const router = createBrowserRouter(
[
    {
        element: <Layout/>,
        children: [...indexRoute, ...publicRoutes, ...profileRoutes, ...panelRoutes, ...adminRoutes]
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="734080589372-rj7m3ca8i193q8uvqvh3uupas795ng3b.apps.googleusercontent.com">
            <UserProvider>
                <RouterProvider router={router}/>
            </UserProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
