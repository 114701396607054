import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";

import {useUser} from "../../contexts/UserContext";
import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";
import NewFixedReservationForm from "../../components/panel/NewFixedReservationForm";
import FixedReservationModal from "../../components/panel/FixedReservationModal";


export default function FixedReservations()
{
    const {managedClub} = useUser();

    const [courts, setCourts] = useState([]);

    const days =
    [
        {id: 1, name: "Lunes"},
        {id: 2, name: "Martes"},
        {id: 3, name: "Miércoles"},
        {id: 4, name: "Jueves"},
        {id: 5, name: "Viernes"},
        {id: 6, name: "Sábado"},
        {id: 7, name: "Domingo"},
    ];


    // Funcion para obtener todas las reservas segun el club y la fecha
    const fetchClub = useCallback(() =>
    {
        if(!managedClub) return;

        // Obtener datos del club como las reservas hechas en la fecha seleccionada
        request("GET", `/panel/fixed-reservations?clubId=${managedClub.id}`).then(response =>
        {
            setCourts(response.data);
            hideModals();
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedClub]);

    useEffect(() =>
    {
        fetchClub();
    }, [fetchClub]);

    useEffect(() =>
    {
        if(!managedClub) return;
        
        document.title = `Reservas fijas - ${managedClub.name}`;
    }, [managedClub]);


    return (
        <>{(managedClub) &&
            <main>
                <div className="mb-3">
                    <p className="mb-0 fs-4 fw-semibold">Reservas fijas</p>
                    <small className="text-muted">Las reservas fijas son reservas que se repiten cada semana el mismo día, a la misma hora y en la misma cancha.</small>
                </div>
 
                {/*  */}
                <div className="accordion" id="fixedReservationsAccordion">
                    {courts.map(court =>
                    {
                        const collapse = `collapse_${court.id}`;
                        const newModal = `fixedReservationModal_new_${court.id}`;

                        return (
                            <div className="accordion-item" key={court.id}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + collapse} aria-expanded="false" aria-controls={collapse}>
                                        {court.name}
                                    </button>
                                </h2>

                                <div className="accordion-collapse collapse" id={collapse} data-bs-parent="#fixedReservationsAccordion">
                                    <div className="accordion-body">
                                        <div className="mb-3">
                                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#" + newModal}>
                                                <div className="hstack gap-2">
                                                    <i className="fa-regular fa-plus"></i>
                                                    <span>Nueva reserva fija</span>
                                                </div>
                                            </button>

                                            <NewFixedReservationForm fixedReservation={null} court={court} modal={newModal} onSubmit={fetchClub}>

                                            </NewFixedReservationForm>
                                        </div>

                                        <div className="overflow-x-auto">
                                            <table className="table table-striped-columns table-bordered mb-2">
                                                <thead className="sticky-top">
                                                    <tr>
                                                        {days.map(day =>
                                                        {
                                                            return (
                                                                <th className="text-center" key={day.id}>{day.name}</th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        {days.map(day =>
                                                        {
                                                            return (
                                                                <td key={day.id}>
                                                                    <div className="vstack gap-2">
                                                                        {court.fixedReservations.map(fixedReservation =>
                                                                        {
                                                                            if(fixedReservation.dayOfWeek !== day.id) return null;

                                                                            const modal = `fixedReservationModal_${fixedReservation.id}`;

                                                                            return (
                                                                                <React.Fragment key={fixedReservation.id}>
                                                                                    <button className="btn btn-success shadow-sm" data-bs-toggle="modal" data-bs-target={"#" + modal}>
                                                                                        <div className="vstack gap-1">
                                                                                            <div className="fw-semibold">
                                                                                                {fixedReservation.startHour}:00 - {fixedReservation.endHour}:00
                                                                                            </div>

                                                                                            <div className="hstack gap-2 small">
                                                                                                <i className="fa-regular fa-user"></i>
                                                                                                <span>
                                                                                                    {(fixedReservation.user) ?
                                                                                                        <React.Fragment>{fixedReservation.user.name} {fixedReservation.user.surname}</React.Fragment>
                                                                                                        :
                                                                                                        <React.Fragment>{fixedReservation.playerName}</React.Fragment>
                                                                                                    }
                                                                                                </span>
                                                                                            </div>

                                                                                            <div className="hstack gap-2 small">
                                                                                                <i className="fa-regular fa-timer"></i>
                                                                                                <span>{fixedReservation.weeksRemaining} semanas</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </button>

                                                                                    <FixedReservationModal fixedReservation={fixedReservation} court={court} modal={modal}>

                                                                                    </FixedReservationModal>
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </main>
        }</>
    );
}
