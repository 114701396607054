import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {hideModals, pad} from "../../services/UtilsService";
import ScheduleForm from "../../components/panel/ScheduleForm";
import {useUser} from "../../contexts/UserContext";


export default function Schedules()
{
    const {managedClub} = useUser();

    const [schedules, setSchedules] = useState([]);

    const days =
    [
        {id: 1, name: "Lunes"},
        {id: 2, name: "Martes"},
        {id: 3, name: "Miércoles"},
        {id: 4, name: "Jueves"},
        {id: 5, name: "Viernes"},
        {id: 6, name: "Sábado"},
        {id: 7, name: "Domingo"},
        {id: 8, name: "Feriados"}
    ];


    // Obtener horarios del club
    const fetchSchedules = useCallback(() =>
    {
        if(!managedClub) return;

        request("GET", `/panel/schedules?clubId=${managedClub.id}`).then(response => {
            setSchedules(response.data);
            hideModals();
        }).catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedClub]);

    useEffect(() =>
    {
        fetchSchedules();
    }, [fetchSchedules]);


    useEffect(() =>
    {
        if(!managedClub) return;
        
        document.title = `Horarios de atención - ${managedClub.name}`;
    }, [managedClub]);


    return (
        <>{(schedules) &&
            <main>
                {/* {(club.subscription.state !== "ACTIVE") &&
                    <SubscriptionStatusAlert suscripcion={club.subscription}/>
                }

                <div className="mb-4 d-flex align-items-start justify-content-between gap-2">
                    <h2 className="mb-0">
                        <Link to={`/clubes/${club.slug}`} className="fs-1 link-dark link-underline link-underline-opacity-0 link-underline-opacity-75-hover">{club.name}</Link>
                    </h2>

                    <Navbar> </Navbar>
                </div>

                <hr /> */}

                <div className="mb-3">
                    <p className="mb-0 fs-4 fw-semibold">Horarios de atención</p>
                    <small className="text-muted">Edita los días y horarios de atención del club. Las reservas solo se podrán hacer dentro de los días y horarios disponibles.</small>
                </div>

                <div className="bg-white border rounded overflow-hidden w-100 shadow-sm" style={{maxWidth: "30rem"}}>
                    <div className="overflow-x-auto">
                        <table className="table table-transparent mb-0 align-middle">
                            <thead>
                                <tr className="text-bg-success">
                                    <th className="text-white fw-bold">Día</th>
                                    <th className="px-3 text-white fw-bold text-center">Apertura</th>
                                    <th className="px-3 text-white fw-bold text-center">Cierre</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {days.map(day =>
                                {
                                    const schedule = schedules.find(schedule => (schedule.dayId === day.id));
                                    
                                    return (
                                        <tr key={day.id}>
                                            {(schedule) ?
                                                <>
                                                    <td className="w-100 fw-semibold">
                                                        <div className="hstack gap-2">
                                                            <i className="fa-thin fa-calendar-day"></i>
                                                            <span>{day.name}</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-center ls-1">{`${pad(schedule.openingHour)}:00`}</td>
                                                    <td className="text-center ls-1">{`${pad(schedule.closingHour)}:00`}</td> 
                                                </>
                                                :
                                                <>
                                                    <td className="w-100 text-muted">
                                                        <div className="hstack gap-2">
                                                            <i className="fa-thin fa-calendar-day"></i>
                                                            <span>{day.name}</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-center ls-1 text-muted">--:--</td>
                                                    <td className="text-center ls-1 text-muted">--:--</td>       
                                                </>
                                            }

                                            <td>
                                                {(schedule) ?
                                                    <button type="button" className="btn btn-sm btn-light" title="Editar horario" data-bs-toggle="modal" data-bs-target={`#horarioModal_${day.id}`}>
                                                        <i className="fa-regular fa-pen-to-square"></i>
                                                    </button>
                                                    :
                                                    <button type="button" className="btn btn-sm btn-light" title="Añadir horario" data-bs-toggle="modal" data-bs-target={`#horarioModal_${day.id}`}>
                                                        <i className="fa-regular fa-plus"></i>
                                                    </button>
                                                }

                                                <ScheduleForm schedule={schedule} day={day} modal={`horarioModal_${day.id}`} onSubmit={fetchSchedules}>

                                                </ScheduleForm>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        }</>
    );
} 