import React, {useEffect, useState} from "react";
import moment from "moment";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import { useUser } from "../../contexts/UserContext";


export default function Subscription()
{
    const {managedClub} = useUser();

    const [subscription, setSubscription] = useState(null);


    useEffect(() =>
    {
        if(!managedClub) return;

        request("GET", `/panel/subscription?clubId=${managedClub.id}`).then(response => {
            setSubscription(response.data);
        }).catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedClub]);


    useEffect(() =>
    {
        if(!managedClub) return;
        
        document.title = `Suscripción - ${managedClub.name}`;
    }, [managedClub]);


    return (
        <React.Fragment>
            {(managedClub) &&
                <main>
                    {/* {(subscription.state !== "ACTIVE") &&
                        <SubscriptionStatusAlert suscripcion={subscription}/>
                    }

                    <div className="mb-4 d-flex align-items-start justify-content-between gap-2">
                        <h2 className="mb-0">
                            <Link to={`/clubes/${subscription.slug}`} className="fs-1 link-dark link-underline link-underline-opacity-0 link-underline-opacity-75-hover">{subscription.name}</Link>
                        </h2>

                        <Navbar> </Navbar>
                    </div>

                    <hr /> */}

                    <div className="mb-3">
                        <p className="mb-0 fs-4 fw-semibold">Suscripción</p>
                        <small className="text-muted">Información sobre el estado de la suscripción, pagos y plan del club.</small>
                    </div>


                    {/*  */}
                    {(subscription) &&
                        <div className="row g-4" style={{maxWidth: "55rem"}}>
                            <div className="col-12 col-md-6">
                                <div className="p-4 bg-white border rounded-3 shadow">
                                    <div className="mb-3 d-flex align-items-center justify-content-between gap-4">
                                        <h3 className="mb-0">Detalles</h3>

                                        {(subscription.freeTrial) &&
                                            <div className="p-2 badge bg-primary">Prueba gratuita</div>
                                        }
                                    </div>

                                    <hr />

                                    <div className="vstack gap-2">
                                        <div className="hstack justify-content-between gap-4">
                                            <p className="mb-0 fw-semibold">Estado</p>
                                            <p className="mb-0 fs-5 fw-semibold">
                                                {(subscription.state === "ACTIVE") && <span className="text-success">Activa</span>}
                                                {(subscription.state === "ABOUT_TO_EXPIRE") && <span className="text-warning">Próximo a vencer</span>}
                                                {(subscription.state === "EXPIRED") && <span className="text-danger">Vencido</span>}
                                                {(subscription.state === "CANCELLED") && <span className="text-black">Cancelado</span>}
                                            </p>
                                        </div>

                                        <div className="hstack justify-content-between gap-4">
                                            <p className="mb-0 fw-semibold">Fecha de inicio</p>
                                            <p className="mb-0">{moment(subscription.startDate).format("ll")}</p>
                                        </div>

                                        <div className="hstack justify-content-between gap-4">
                                            <p className="mb-0 fw-semibold">Próximo pago</p>
                                            <p className="mb-0">{moment(subscription.nextPaymentDate).format("ll")}</p>
                                        </div>

                                        {(subscription.daysUntilExpiration >= 0) ?
                                            <div className="d-flex align-items-start justify-content-between gap-2">
                                                <span className="fw-semibold">Vence en</span>
                                                <span>{subscription.daysUntilExpiration} días</span>
                                            </div>
                                            :
                                            <div className="d-flex align-items-start justify-content-between gap-2 fw-semibold text-danger">
                                                <span>Venció hace</span>
                                                <span>{-subscription.daysUntilExpiration} días</span>
                                            </div>
                                        }
                                    </div>

                                    <hr />

                                    <div>
                                        <p className="fw-semibold text-muted">Historial de pagos</p>

                                        {(subscription.payments.length) ?
                                            <div className="overflow-x-auto">
                                                <table className="table align-middle">
                                                    <thead>
                                                        <tr>
                                                            <th>Fecha</th>
                                                            <th>Monto</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    
                                                    <tbody>
                                                        {subscription.payments.map(payment =>
                                                        {
                                                            return (
                                                                <tr key={payment.id}>
                                                                    <td className="text-muted">{moment(payment.date).format("lll")}</td>
                                                                    <td className="fw-semibold text-success text-nowrap">$ {payment.amount.toLocaleString()}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="mb-0 alert alert-secondary show" role="alert">
                                                Aún no hay pagos registrados
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="p-4 bg-white border rounded-4 shadow">
                                    <div className="mb-3">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 text-center">
                                            <h3 className="mb-0">Tu plan</h3>

                                            <div className="hstack gap-1 text-nowrap text-success">
                                                <p className="mb-0 fs-5 fw-bold">AR$ {subscription.plan.finalPrice.toLocaleString()}</p>
                                                <small>/mes</small>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <p className="text-muted">Beneficios</p>

                                    <ul className="mb-0 list-unstyled vstack gap-2">
                                        <li className="hstack gap-2">
                                            <i className="fa-solid fa-check text-primary"></i>

                                            <span className="fw-bold">
                                                {(subscription.plan.maxCourts === 0) && "¡Canchas ilimitadas!"}
                                                {(subscription.plan.maxCourts === 1) && "1 cancha"}
                                                {(subscription.plan.maxCourts > 1) && `${subscription.plan.maxCourts} canchas`}
                                            </span>
                                        </li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-primary"></i> Página exclusiva para tu club</li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-primary"></i> Reservas en tiempo real</li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-primary"></i> Panel de control moderno</li>
                                        <li className="hstack gap-2"><i className="fa-solid fa-check text-primary"></i> Soporte técnico sin costo</li>
                                    </ul>

                                    <hr />

                                    <div className="text-center">
                                        <p className="mb-2 fw-bold">¿Deseas mejorar tu plan?</p>

                                        <a href={"http://wa.me/5493644382805"}className="btn btn-success" target="_blank" rel="external noopener noreferrer" title="WhatsApp">
                                            <div className="hstack gap-2">
                                                <i className="fa-brands fa-whatsapp"></i>
                                                <span>Contáctanos</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>
            }
        </React.Fragment>
    );
} 