import React, {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import moment from "moment";

import {request} from "../../services/AxiosService";
import {LocalDate, getDayName} from "../../services/UtilsService";
import {formatTime} from "../../services/FormatTime";
import NotFound from "./NotFound";
import API_RESPONSE from "../../services/ApiResponse";


export default function Club()
{
    const {slug} = useParams();
    const [params] = useSearchParams();

    const [club, setClub] = useState({});
    const [apiResponse, setApiResponse] = useState({});

    const [filters, setFilters] = useState({});
    const [timeSlots, setTimeSlots] = useState([]);
    const [availableCourts, setAvailableCourts] = useState([]);

    const [notFound, setNotFound] = useState(false);

    const minDate = moment(new Date()).format("yyyy-MM-DD");
    const maxDate = moment(new Date()).add("6", "days").format("yyyy-MM-DD");


    // Obtener datos del club
    useEffect(() =>
    {
        request("GET", `/clubs/${slug}`).then(response => {
            setClub(response.data);
        })
        .catch(() => {
            setNotFound(true); // Club no encontrado, o cualquier otro problema
        });
    }, [slug, params]);

    // Cuando carga la informacion del club
    useEffect(() =>
    {
        // Cambiar titulo de pagina
        if(club.name)
        {
            document.title = `Reserva en ${club.name}`;
        }

        if(!club.sports || !club.schedules) return;

        const sportId = parseInt(params.get("deporteId") || null);
        const sport = (sportId) ? (club.sports.find(sport => (sport.id === sportId))) : (club.sports[0] || {});
        setFilters({date: LocalDate.now().toString(), sport});
    }, [club, params]);

    // Cuando se cambian los filtros de busqueda
    useEffect(() =>
    {
        if(!club.id || !filters.sport || !filters.date) return;

        // Limpiar los timeslots hasta que carguen con la nueva informacion
        setTimeSlots([]);
        setAvailableCourts([]);
        setApiResponse({});
        setFilters(prevFitlers => ({...prevFitlers, time: null}));

        request("GET", `/reservations/time-slots?clubId=${club.id}&sportId=${filters.sport.id}&date=${filters.date}`).then(response => {
            setTimeSlots(response.data); // Actualizar las horas disponibles
        })
        .catch(error => 
        {
            setApiResponse(error.response.data);

            // Reseteamos los elementos en caso de ocurrir un problema
            setAvailableCourts([]);
            setTimeSlots([]);
        });
    }, [club.id, filters.sport, filters.date]);

    
    // Cuando el usuario selecciona una hora para ver las canchas disponibles
    const onSelectTimeSlot = (timeSlot) =>
    {
        if(timeSlot.courts.length > 0)
        {
            setFilters({...filters, time: timeSlot.time, timeslot: timeSlot});
            setAvailableCourts(timeSlot.courts);
        }
    };
    

    // Club inexistente
    if(notFound === true)
    {
        return (
            <NotFound> </NotFound>
        );
    }


    return (
        <>{(Object.keys(club).length > 0) &&
            <React.Fragment>
                {/* Portada */}
                {(club.coverUrl) &&
                    <div className="club-cover shadow" style={{backgroundImage: `url("${club.coverUrl}")`}}>

                    </div>
                }

                <main className="container-xl my-3">
                    {/* Logo y nombre */}
                    <div className="mb-4">
                        <div className="hstack gap-3">
                            {club.logoUrl &&
                                <div className="border rounded-4 overflow-hidden shadow" style={{width: "15vw", height: "15vw", minWidth: "6rem", minHeight: "6rem", maxWidth: "8rem", maxHeight: "8rem"}}>
                                    <img src={club.logoUrl} className="w-100 h-100 object-fit-contain" alt="Logo"/>
                                </div>
                            }

                            <div>
                                <h2 className="mb-0 display-5 fw-bold">{club.name}</h2>
                                <div className="hstack gap-1 text-muted">
                                    <i className="fa-regular fa-location-dot fa-fw"></i>
                                    <p className="mb-0">{club.address}</p>
                                </div>

                                <p className="mb-0 small">
                                    {(club.open) &&
                                        <span className="fw-bold text-success">Abierto ahora</span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4">
                        {/* Reservar */}
                        <div className="col-12 col-lg-7 col-xl-6">
                            <div className="px-3 py-4 border rounded-4 shadow">
                                <div className="text-center">
                                    <h3 className="mb-0 fs-3 fw-bold text-primary">Haz tu reserva</h3>
                                </div>

                                <hr className="my-4"/>

                                {/* Deporte */}
                                <div className="mb-4 hstack gap-2">
                                    <div className="w-100">
                                        <label className="form-label fw-semibold" htmlFor="sport">Quiero jugar</label>

                                        <div className="dropdown">
                                            <button className="btn w-100 border shadow-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="d-flex align-items-center justify-content-between gap-3">
                                                    {(filters.sport) ?
                                                        <div className="hstack gap-1">
                                                            <i className={`fa-regular ${filters.sport.icon} text-primary fa-fw`}></i>
                                                            <span>{filters.sport.name}</span>
                                                        </div>
                                                        :
                                                        <span>Seleccionar</span>
                                                    }

                                                    <i className="fa-regular fa-chevron-down"></i>
                                                </div>
                                            </button>

                                            <ul className="dropdown-menu w-100 shadow">
                                                {club.sports.map(sport =>
                                                {
                                                    return (
                                                        <li key={sport.id}>
                                                            <button className="dropdown-item" onClick={() => setFilters({...filters, sport})}>
                                                                <div className="hstack gap-1">
                                                                    <i className={`fa-regular ${sport.icon} text-primary fa-fw`}></i>
                                                                    <span>{sport.name}</span>
                                                                </div>
                                                            </button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="w-100">
                                        <label className="form-label fw-semibold" htmlFor="sport">Fecha</label>
                                        <input className="form-control shadow-sm" type="date" value={filters.date || ""} onChange={e => setFilters({...filters, date: e.target.value})} min={minDate} max={maxDate} placeholder="Fecha"/>
                                    </div>
                                </div>

                                {/* Horas disponibles */}
                                {(timeSlots.length > 0 && filters.date) &&
                                    <div className="mb-4">
                                        <div className="mb-2 text-md-start">
                                            <p className="mb-0 fw-semibold">Elige tu hora</p>
                                        </div>

                                        {(!timeSlots.find(timeslot => timeslot.courts.length > 0)) &&
                                            <div className="alert alert-warning text-center" role="alert">
                                                <p className="mb-0">Todas las horas están ocupadas.<br/><span className="fw-semibold">Intenta con otra fecha.</span></p>
                                            </div>
                                        }

                                        <div className="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-3 row-cols-xl-4 g-2">
                                            {timeSlots.map((timeSlot, timeSlotIndex) =>
                                            {
                                                const hour = formatTime(timeSlot.time, "HH:mm");
                                                const design = (timeSlot.time === filters.time) ? ("bg-success bg-opacity-10 border-success") : ("");

                                                // Verificar si la fecha del turno no es una fecha pasada 
                                                const timeslotDate = new Date(`${timeSlot.date}T${timeSlot.time}`); // Convertir la fecha del turno a Date
                                                const currentDate = new Date(); // Obtener la fecha y hora actual del usuario
                                                const available = (timeSlot.courts.length > 0) && (timeslotDate >= currentDate); // Si el timeslot tiene canchas disponibles y la fecha de la reserva es una fecha futura, entonces habilitar el timeslot
                                                
                                                return (
                                                    <div className="col" key={timeSlotIndex}>
                                                        {(available) ?
                                                            <button className="btn-unstyled w-100" type="button" onClick={() => onSelectTimeSlot(timeSlot)}>
                                                                <div className={`px-3 py-2 ${design} border rounded-2 shadow-sm`} >
                                                                    <span className="fw-bold text-success">{hour}</span>
                                                                </div>
                                                            </button>
                                                            :
                                                            <button type="button" className="w-100 px-3 p-2 border rounded-2" disabled>
                                                                <span className="fw-semibold">{hour}</span>
                                                            </button>
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                }

                                {(apiResponse.code === API_RESPONSE.SCHEDULE_NOT_FOUND) &&
                                    <div className="mb-0 alert alert-danger text-center" role="alert">
                                        <h4 className="alert-heading">Día de descanso</h4>
                                        <p className="mb-0">El club no abre los días <span className="text-lowercase">{getDayName(filters.date)}</span>.</p>
                                        <p className="mb-0 fw-semibold">¡Aún puedes reservar selecciondo otra fecha!</p>
                                    </div>
                                }

                                {/* Canchas disponibles */}
                                {(filters.time && availableCourts.length > 0) &&
                                    <div>
                                        <div className="mb-2 text-center text-md-start">
                                            <p className="mb-0 fw-semibold">Canchas disponibles para las {moment(`${filters.date} ${filters.time}`).format("HH:mm")}</p>
                                        </div>

                                        {availableCourts.map(court =>
                                        {
                                            const hour = formatTime(filters.time, "HH");

                                            return (
                                                <React.Fragment key={court.id}>
                                                    <hr />

                                                    <div>
                                                        <div className="d-flex align-items-center justify-content-between gap-3">
                                                            <div className="text-truncate">
                                                                <p className="mb-0 fw-bold">{court.name}</p>

                                                                <p className="mb-0 text-wrap">
                                                                    <span>{court.prices[0].duration} minutos</span>
                                                                    <span> - </span>
                                                                    <span className="fw-semibold text-success text-nowrap">$ {court.prices[0].price.toLocaleString()}</span>
                                                                </p>
                                                            </div>

                                                            <div>
                                                                {/* <button className="btn btn-success py-2 fw-semibold" type="button" onClick={() => onReserve(court, court.prices[0])}>Reservar</button> */}
                                                                <Link to={`/reservar?fecha=${filters.timeslot.date}&hora=${hour}&ref=${court.prices[0].id}`} className="btn btn-success py-2 fw-semibold">Reservar</Link>
                                                            </div>
                                                        </div>

                                                        {(court.prices.length) > 1 &&
                                                            <div>
                                                                <button type="button" className="btn-unstyled text-muted small" data-bs-toggle="collapse" data-bs-target={`#precioCollapse_${court.id}`} aria-expanded="false" aria-controls="collapseWidthExample">
                                                                    Más opciones <i className="fa-regular fa-chevron-down"></i>
                                                                </button>

                                                                <div className="collapse" id={`precioCollapse_${court.id}`}>
                                                                    <div className="d-flex flex-column gap-1">
                                                                        {court.prices.map((price, priceIndex) =>
                                                                        {
                                                                            if(priceIndex === 0) return ( <React.Fragment key={priceIndex}> </React.Fragment> );

                                                                            return (
                                                                                <div className="d-flex align-items-center justify-content-between gap-3" key={priceIndex}>
                                                                                    <div>
                                                                                        <p className="mb-0">
                                                                                            <span>{price.duration} minutos</span>
                                                                                            <span> - </span>
                                                                                            <span className="fw-semibold text-success">$ {price.price.toLocaleString()}</span>
                                                                                        </p>
                                                                                    </div>

                                                                                    <div>
                                                                                        {/* <button className="btn btn-success py-2 fw-semibold" type="button" onClick={() => onReserve(court, price)}>Reservar</button> */}
                                                                                        <Link to={`/reservar?fecha=${filters.timeslot.date}&hora=${hour}&ref=${price.id}`} className="btn btn-success py-2">Reservar</Link>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        </div>

                        {/* Horarios */}
                        <div className="col-12 col-lg col-xl-6">
                            {(club.schedules) &&
                                <div className="p-4 border rounded-3 shadow">
                                    <div className="mb-4 text-center text-md-start">
                                        <h3 className="mb-0 fs-3 fw-semibold">Horarios de atención</h3>
                                        <p className="mb-0 text-muted">Revisa si el club atiende en los días feriados.</p>
                                    </div>

                                    <div className="vstack gap-2">
                                        {[0, 1, 2, 3, 4, 5, 6, 7].map(dow =>
                                        {
                                            const dayName = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo", "Feriados"];
                                            const schedule = club.schedules.find(schedule => (schedule.dayId === (dow + 1)));

                                            return (
                                                <div className="d-flex align-items-center justify-content-between gap-3" key={dow}>
                                                    <p className="mb-0 fw-semibold">{dayName[dow]}</p>
                                                    <p className="mb-0 text-muted">
                                                        {(schedule) ?
                                                            <span>{schedule.openingHour}:00 - {schedule.closingHour}:00</span>
                                                            :
                                                            <span>Cerrado</span>
                                                        }
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        </div>


                        {/* Como llegar */}
                        <div className="col-12" id="ubicacion">
                            <hr className="mb-4"/>

                            <div className="mb-3">
                                <h3 className="mb-0 fw-semibold">Ubicación</h3>
                            </div>

                            <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                <div className="hstack gap-2 fs-5">
                                    <i className="fa-thin fa-location-dot"></i>
                                    <span>{club.address}</span>
                                </div>

                                {/* <div>
                                    <a 
                                        href="https://www.google.com/maps/dir//-25.9408327,-60.6395387/@-25.9409339,-60.6399428,219m/data=!3m1!1e3!4m2!4m1!3e0?entry=ttu"
                                        className="btn btn-primary w-auto"
                                        target="_blank"
                                        rel="external noopener noreferrer"
                                        >
                                        <div className="hstack gap-2">
                                            <i className="fa-solid fa-directions fs-6"></i>
                                            <span>Ver indicaciones</span>
                                        </div>
                                    </a>
                                </div> */}
                            </div>

                            <div className="mb-3 d-flex flex-column flex-lg-row align-items-lg-center justify-content-between gap-1">
                                
                            </div>

                            {(club.iframeSrc) &&
                                <div>
                                    <iframe 
                                        src={club.iframeSrc} 
                                        style={{width: "100%", height: "20rem", border: "0", borderRadius: "16px"}} 
                                        allowFullScreen="" 
                                        loading="lazy" 
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Mapa"
                                        >
                                    </iframe>
                                </div>
                            }
                        </div>
                    </div>
                </main>
            </React.Fragment>
        }</>
    );
}
