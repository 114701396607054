const API_RESPONSE =
{
    CLUB_NOT_FOUND: "CLUB_NOT_FOUND",
    COURT_NOT_FOUND: "COURT_NOT_FOUND",
    PRICE_NOT_FOUND: "PRICE_NOT_FOUND",
    SPORT_NOT_FOUND: "SPORT_NOT_FOUND",
    SCHEDULE_NOT_FOUND: "SCHEDULE_NOT_FOUND",
    RESERVATION_NOT_FOUND: "RESERVATION_NOT_FOUND",
    FIXEDRESERVATION_NOT_FOUND: "FIXEDRESERVATION_NOT_FOUND",
    PLAN_NOT_FOUND: "PLAN_NOT_FOUND",
    SUBSCRIPTION_NOT_FOUND: "SUBSCRIPTION_NOT_FOUND",
    PAYMENT_NOT_FOUND: "PAYMENT_NOT_FOUND",
    USER_NOT_FOUND: "USER_NOT_FOUND"
};

export default API_RESPONSE;