import {Link, useLocation} from 'react-router-dom';

import {panelLinks} from '../../routes/layouts';


export default function Sidebar()
{
    const location = useLocation();


    return (
        <div className="collapse collapse-horizontal show me-4" id="collapsePanelSidebar">
            <div className="vstack gap-2">
                {panelLinks.map(link =>
                {
                    const pathname = "/panel/" + link.href + "/";
                    const active = ((location.pathname + "/").startsWith(pathname));

                    const btnClass = (active) ? ("btn-primary fw-semibold") : ("btn-light");
                    const iconClass = (active) ? ("fa-solid") : ("fa-thin");


                    return (
                        <Link to={link.href} className={`btn ${btnClass}`} key={link.href}>
                            <div className="hstack gap-2">
                                <i className={`${iconClass} ${link.icon} fa-fw`}></i>
                                <span>{link.title}</span>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}
