import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {initMercadoPago, Wallet} from "@mercadopago/sdk-react";
import Swal from "sweetalert2";
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import "moment/locale/es";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";
import {LocalDate} from "../../services/UtilsService";
import PAYMENT_TYPE from "../../services/PAYMENT_TYPE";


export default function Reserve()
{
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {user, setRedirectPath} = useUser();
    const [reservation, setReservation] = useState(null);
    const [form, setForm] = useState(null);
    const [idempotencyKey] = useState(uuidv4());
    const now = new Date().toDateString();

    
    // Obtener datos de donde reservará el jugador
    const fetchReservation = useCallback(() =>
    {
        const date = params.get("fecha");
        const hour = params.get("hora");
        const priceId = params.get("ref");

        request("GET", `/reservations/details?date=${date}&hour=${hour}&priceId=${priceId}`).then(response => 
        {
            setReservation(response.data);
            setForm({date, hour, priceId, idempotencyKey});
            
            // Inicializar MercadoPago
            const publicKey = process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY;
            initMercadoPago(publicKey);

            // Cambiar titulo de pagina
            const {name} = response.data.club;
            document.title = `Confirma tu reserva en ${name} | Reservar Cancha`;
        })
        .catch(() => 
        {
            Swal.fire(
            {
                icon: "error",
                title: "Error inesperado",
                text: "Ocurrió un error y no supimos cómo resolver",
                confirmButtonText: "Volver"
            })
            .then(() => {
                navigate(-1);
            });
        });
    }, [params, idempotencyKey, navigate]);

    useEffect(() =>
    {
        fetchReservation();
    }, [fetchReservation]);


    // Reservar cancha
    const onReservate = () =>
    {
        if(!form) return;

        request("POST", "/reservations/reserve", form).then(() => 
        {
            Swal.fire(
            {
                icon: "success",
                title: `¡A jugar ${reservation.sport.name}!`,
                text: `Tu reserva en "${reservation.club.name}" fue realizada con éxito`,
                confirmButtonText: "Ir a mis reservas"
            })
            .then(() =>
            {
                navigate("/reservas");
            });
        })
        .catch(error => 
        {   
            Swal.fire(
            {
                title: "Hora ocupada",
                text: "Esta hora ya fue reservada por otro jugador. Elige otra opción.",
                icon: "error",
                confirmButtonText: "Volver"
            }).then(() =>
            {
                const href = `/clubes/${reservation.club.slug}`;
                navigate(href);
            });
        });
    }

    // Enviar al usuario a autenticar para que pueda reservar
    const sendToAuth = (path) =>
    {
        const urlPath = window.location.pathname + window.location.search; // Obtiene la url con los parametros de busqueda
        setRedirectPath(urlPath); // Guardar url para redireccionar al usuario luego de que inicie sesion
        navigate(path);
    };


    return (
        <main className="container-lg my-4">
            {reservation &&
                <>
                    <div className="text-center text-lg-start">
                        <h2 className="mb-0">¡Casi listo!</h2>
                        <p className="mb-0">Verificá los detalles y confirmá tu reserva</p>
                    </div>

                    {/* <hr className="my-4"/> */}

                    <div className="row row-cols-1 row-cols-lg-2 g-4">
                        <div className="col">
                            <hr className="my-4"/>

                            <div className="p-3 bg-white border rounded shadow">
                                <div className="mb-3 d-flex gap-3">
                                    {reservation.club.logoUrl &&
                                        <div className="p-2 bg-dark rounded" style={{width: "15vw", height: "15vw", minWidth: "5rem", minHeight: "5rem", maxWidth: "8rem", maxHeight: "8rem"}}>
                                            <img src={reservation.club.logoUrl} className="w-100 h-100 object-fit-contain" alt="Logo"/>
                                        </div>
                                    }

                                    <div>
                                        <h3 className="mb-1 fw-bold">{reservation.club.name}</h3>
                                        <h4 className="fs-6 fw-normal text-muted"><i className="fa-solid fa-location-dot"></i> {reservation.club.address}</h4>
                                    </div>
                                </div>
                                
                                <div className="mb-4 row align-items-center row-cols-1 row-cols-lg-2 g-3">
                                    <div className="col">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                                <i className="fa-regular fa-calendar fs-5 text-primary"></i>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                                                <div>
                                                    <p className="mb-0 text-muted">Fecha</p>
                                                    <p className="mb-0 fw-semibold">
                                                        {(new Date(reservation.startTime).toDateString() === LocalDate.now().toString()) && 
                                                            <span>Hoy a las {moment(reservation.startTime).format("HH:mm")}</span>
                                                        }
                                                        {(new Date(reservation.startTime).toDateString() === LocalDate.now().plusDays(1).toString()) &&
                                                            <span>Mañana a las {moment(reservation.startTime).format("HH:mm")}</span>
                                                        }
                                                        {(new Date(reservation.startTime).toDateString() >= LocalDate.now().plusDays(2).toString()) &&
                                                            <span>{moment(reservation.startTime).format("dddd[,] DD [de] MMMM [a las] HH:mm")}</span>
                                                        }
                                                    </p>
                                                </div>

                                                <div className="text-center">
                                                    <Link to={`/clubes/${reservation.club.slug}?deporteId=${reservation.sport.id}`} className="small fw-medium">Cambiar</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                                <i className="fa-regular fa-futbol fs-5 text-primary"></i>
                                            </div>

                                            <div>
                                                <p className="mb-0 text-muted">Datos del partido</p>
                                                <p className="mb-0"><span className="fw-semibold">{reservation.sport.name}</span> en <span className="fw-semibold">{reservation.court.name}</span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                                <i className="fa-regular fa-stopwatch fs-5 text-primary"></i>
                                            </div>

                                            <div>
                                                <p className="mb-0 text-muted">Duración</p>
                                                <p className="mb-0 fw-semibold">{reservation.duration} minutos</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                                <i className="fa-solid fa-dollar fs-5 text-primary"></i>
                                            </div>

                                            <div>
                                                <p className="mb-0 text-muted">Total</p>
                                                <p className="mb-0 fw-bold">$ {reservation.price.toLocaleString()}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {(reservation.paymentType.PARTIAL) &&
                                        <div className="col">
                                            <div className="d-flex align-items-center gap-2">
                                                <div className="d-flex align-items-center justify-content-center text-bg-success bg-opacity-10 rounded-3" style={{minWidth: "48px", minHeight: "48px"}}>
                                                    <i className="fa-regular fa-hand-holding-dollar fs-5 text-success"></i>
                                                </div>

                                                <div>
                                                    <p className="mb-0 text-muted">Seña/adelanto</p>
                                                    <p className="mb-0 fw-bold">$ {reservation.payment.depositAmount.toLocaleString()} / {reservation.price.toLocaleString()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="alert alert-primary" role="alert">
                                    <h4 className="alert-heading fw-bold small text-uppercase">Reserva así:</h4>

                                    {/* Informacion del pago */}
                                    {(reservation.paymentType === PAYMENT_TYPE.PRESENTIAL) &&
                                        <p className="mb-0">Reservá ahora y pagás cuando llegues al complejo deportivo.</p>
                                    }
                                    {(reservation.paymentType === PAYMENT_TYPE.ONLINE) &&
                                        <p className="mb-0">Pagás el importe total ahora y tu reserva queda confirmada.</p>
                                    }
                                    {(reservation.paymentType === PAYMENT_TYPE.PARTIAL) &&
                                        <p className="mb-0">Debes abonar una seña de <span className="fw-bold">${reservation.payment.depositAmount.toLocaleString()}</span> para confirmar tu reserva, luego pagás el resto en el complejo deportivo.</p>
                                    }

                                    {/* Política de cancelación */}
                                    {(new Date() < new Date(reservation.cancelationLimit)) ?
                                        <React.Fragment>
                                            {(reservation.paymentType === PAYMENT_TYPE.PRESENTIAL) &&
                                                <p className="mb-0">Podés cancelar hasta las {moment(reservation.cancelationLimit).format("HH:mm [del] D MMM")} Si decidís cancelas después de esa fecha, el complejo podría restringirte de reservar temporalmente.</p>
                                            }
                                            {(reservation.paymentType === PAYMENT_TYPE.ONLINE) &&
                                                <p className="mb-0">Podés cancelar gratuitamente hasta las {moment(reservation.cancelationLimit).format("HH:mm [del] D MMM")} Si decidís cancelas después de esa fecha, no podrás recibir un reembolso.</p>
                                            }
                                            {(reservation.paymentType === PAYMENT_TYPE.PARTIAL) &&
                                                <p className="mb-0">Podés cancelar gratuitamente hasta las {moment(reservation.cancelationLimit).format("HH:mm [del] D MMM")} Si decidís cancelas después de esa fecha, no podrás recibir un reembolso.</p>
                                            }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {(reservation.paymentType === PAYMENT_TYPE.PRESENTIAL) &&
                                                <p className="mb-0">Tené en cuenta que dado la fecha seleccionada, si decidís cancelar la reserva el complejo podría restringirte temporalmente de reservar.</p>
                                            }
                                            {(reservation.paymentType === PAYMENT_TYPE.ONLINE) &&
                                                <p className="mb-0">Tené en cuenta que dado la fecha seleccionada, si decidís cancelar la reserva ya no podrás recibir un reembolso.</p>
                                            }
                                            {(reservation.paymentType === PAYMENT_TYPE.PARTIAL) &&
                                                <p className="mb-0">Tené en cuenta que dado la fecha seleccionada, si decidís cancelar la reserva ya no podrás recibir un reembolso.</p>
                                            }
                                        </React.Fragment>
                                    }
                                </div>

                                {(user) ?
                                    <React.Fragment>
                                        {(reservation.paymentType === PAYMENT_TYPE.PRESENTIAL) ?
                                            <button type="button" className="btn btn-lg btn-success w-100 fw-semibold" onClick={() => onReservate()}>Reservar</button>
                                            :
                                            <Wallet
                                                initialization={{marketplace: true, redirectMode: "blank"}}
                                                onSubmit={async () =>
                                                {
                                                    // Enviar una peticion para generar un preferenceId
                                                    return new Promise((resolve, reject) =>
                                                    {
                                                        request("POST", "/reservations/generate-preference", form).then(response =>
                                                        {
                                                            const preferenceId = response.data; // Obtener el preferenceId desde la respuesta del servidor
                                                            resolve(preferenceId); // Resolver el Promise con el preferenceId
                                                        })
                                                        .catch(error =>
                                                        {
                                                            Swal.fire(
                                                            {
                                                                text: error.response.data,
                                                                icon: "error",
                                                                confirmButtonText: "Volver"
                                                            }).then(() =>
                                                            {
                                                                const href = `/clubes/${reservation.club.slug}`;
                                                                navigate(href);
                                                            });
                                                        });
                                                    });
                                                }}
                                            />
                                        }
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <hr className="mt-4 mb-3"/>

                                        <p className="mb-2 text-center text-muted">Inicia sesión para reservar este turno</p>

                                        <div className="d-flex justify-content-center gap-2">
                                            <button className="btn btn-outline-success fw-semibold" type="button" onClick={() => sendToAuth("/signup")}>Crear cuenta</button>
                                            <button className="btn btn-primary fw-semibold" type="button" onClick={() => sendToAuth("/login")}>Iniciar sesión</button>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>

                        <div className="col">
                            <hr className="my-4"/>

                            <div className="bg-light rounded-4 shadow">
                                <div className="p-3">
                                    <div className="d-flex align-items-center gap-2">
                                        <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-circle" style={{minWidth: "48px", minHeight: "48px"}}>
                                            <i className="fa-regular fa-location-dot fs-4 text-primary"></i>
                                        </div>

                                        <div>
                                            <p className="mb-0 fw-bold">Ubicación</p>
                                            <p className="mb-0 text-muted">{reservation.club.address}</p>
                                        </div>
                                    </div>
                                </div>

                                {(reservation.club.iframeSrc) &&
                                    <iframe 
                                        src={reservation.club.iframeSrc}
                                        style={{width: "100%", height: "20rem", border: "0"}} 
                                        allowFullScreen="" 
                                        loading="lazy" 
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Mapa"
                                        >
                                    </iframe>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </main>
    );
} 