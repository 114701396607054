import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function Clubs()
{
    const navigate = useNavigate();

    const {setManagedClub} = useUser();
    const [clubs, setClubs] = useState([]);


    // Obtener todos los clubes
    useEffect(() =>
    {
        request("GET", "/admin/clubs/").then(response => {
            setClubs(response.data);
        });
    }, []);


    const onManageClub = club =>
    {
        setManagedClub(club);
        navigate("/panel/reservas");
    };


    return (
        <main className="container my-5">
            <div className="mb-4">
                <h2>Lista de clubes</h2>

                <Link to={"/admin/clubes/form"} className="btn btn-primary fw-semibold">
                    <span className="hstack gap-2">
                        <i className="fa-solid fa-plus"></i>
                        <span>Crear club</span>
                    </span>
                </Link>
            </div>

            <div className="border rounded">
                <div className="p-3 border-bottom">
                    <p className="mb-0 text-muted">{clubs.length} clubes registrados</p>
                </div>

                <div className="overflow-x-auto">
                    <table className="mb-0 table table-hover align-middle caption-top">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Página</th>
                                <th>Propietario</th>
                                <th>Suscripción</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {clubs.map(club =>
                            {
                                return (
                                    <tr key={club.id}>
                                        <td className="fw-semibold">
                                            <div className="hstack gap-2">
                                                <i className="fa-thin fa-buildings text-primary"></i>
                                                <span>{club.name}</span>
                                            </div>
                                        </td>
                                        <td><Link to={`/clubes/${club.slug}`}>/{club.slug}</Link></td>
                                        <td>
                                            <div>{club.owner.name} {club.owner.apellido}</div>
                                            <div>{club.owner.email}</div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center gap-3 fw-semibold">
                                                {(club.subscription.state === "ACTIVE") && <span className="text-success">Activo</span>}
                                                {(club.subscription.state === "ABOUT_TO_EXPIRE") && <span className="text-warning">Próximo a vencer</span>}
                                                {(club.subscription.state === "EXPIRED") && <span className="text-danger">Vencido</span>}
                                                {(club.subscription.state === "CANCELLED") && <span className="text-black">Cancelado</span>}

                                                <Link to={`/admin/suscripciones?clubId=${club.id}`} className="btn btn-sm btn-primary">
                                                    <i className="fa-regular fa-eye"></i>
                                                </Link>

                                                <button className="btn btn-sm btn-success" type="button" title="Ir al panel" onClick={() => onManageClub(club)}>
                                                    <i className="fa-regular fa-table-columns"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <Link to={`/admin/clubes/form?id=${club.id}`} className="btn btn-sm btn-light">
                                                <i className="fa-regular fa-pen-square fa-lg"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
} 